import api from '../api';
import store from '@/stores/store';

const state = {
  user: {},
};

const getters = {
  user: (state) => state.user,
};

const mutations = {
  SET_USER(state, data) {
    state.user = data;
  },
};

const actions = {
  async getUser({ commit }) {
    if (store.state.auth.token) {
      try {
        const response = await api.get('/v1/api/profile', {
          headers: {
            'Content-Type': 'application/json',
            'access-token': store.state.auth.token,
          },
        });
        commit('SET_USER', response.data.data.data[0]);
      } catch (error) {
        console.error('loi')
      }
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
