<template>
  <div class="layout-user">
    <Header class="header position-fixed" />
    <div class="content"><router-view :key="$route.path" /></div>
    <Footer />
  </div>
</template>

<script>
  import Header from './Header.vue';
  import Footer from './Footer.vue';
  export default {
    name: 'LayoutUser',
    components: {
      Header,
      Footer,
    },
  };
</script>

<style scoped lang="scss">
  .layout-user {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #000000;
      border-radius: 3px;
      height: 10vh;
    }
    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
      height: 80vh;
    }
    .header {
      width: 100vw;
      background-color: #000000;
      z-index: 3000;
      color: rgb(255, 255, 255);
    }
    .content {
      margin-top:106px;
      z-index:999;
    }
  }
  @media screen and (max-width: 768px) {
    .content {
      margin-top:150px;
    }
  }
</style>
