<template>
  <Workshops :workshops="workshops" />
</template>

<script>
import Workshops from './workshops.vue'
export default {
  components:{
Workshops
  },
data(){
  return{
     workshops: [
            {
              id: 1,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 2,
              name: '24 workshops covering all topics of IELTS exams',
              path: 'workshop2.png',
            },
            {
              id: 3,
              name: 'Mock Exam practice by former IELTS examiners',
              path: 'workshop3.png',
            },
            {
              id: 4,
              name: 'Mock Exam practice by former IELTS examiners',
              path: 'workshop4.png',
            },
          ],
  }
}
}
</script>

<style>

</style>