<template>
  <section>
    <div v-for="menu in menus" :key="menu" class="menu">
      <div class="title">
        <h2>{{ menu.type }}</h2>
      </div>
      <div class="list-items">
        <div
          class="item"
          v-for="(item, index) in menu.list"
          :key="index"
        >
          <div class="image">
            <img :src="require(`../../../public/img/${item.img}`)" />
          </div>
          <div class="description">
            <h3>{{ item.name }}</h3>
            <p>
              <span v-html="item.descriptions"></span>
            </p>
            <p class="price-item">{{ item.price }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Menu',
  props: {
    menus: Array,
  },
  data() {
    return {};
  },
  destroyed() {
    this.$forceUpdate();
  },
};
</script>

<style lang="scss" scoped>
section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .menu {
    max-width: 1600px;
    width: 100%;
    .title {
      text-align: left;
      padding: 0 1rem;
      h2 {
        font-family: 'Great Vibes', cursive;
        color: #c49d63;
        padding-top: 20px;
        font-size: 50px;
      }
    }
    .list-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 8px;
      margin: 8px 0;
      width: calc(100% / 3);
      .image {
        width: 90%;
        height: 20vw;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .description {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        margin-top: 8px;
        h3 {
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 900;
        }
        p {
          color: #898989;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          font-family: 'Poppins', sans-serif;
        }
      }
    }
  }
}
@media only screen and (max-width: 896px) and (min-width: 320px) {
  section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .menu {
    max-width: 1600px;
    width: 100%;
    .title {
      text-align: left;
      padding: 0 1rem;
      h2 {
        font-family: 'Great Vibes', cursive;
        color: #c49d63;
        padding-top: 20px;
        font-size: 24px;
      }
    }
    .list-items {
      flex-direction: column;
    }
    .item {
     margin: 0;
     padding: 0;
      width: 100%;
      .image {
        width: 100vw;
        height: 100vw;
        img {
          padding: 0.5rem;
        }
      }
      .description {
        width: 100vw;
        padding: 0.5rem;
        h3 {
          font-size: 12px;
        }
        p {

          font-size: 10px;
        }
      }
    }
  }
}
}
</style>