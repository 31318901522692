<template>
      <Menu :menus='menus'/>
</template>

<script>
import Menu from '../menu.vue'
export default {
name:'MenuDrink',
components:{
    Menu,
},
data(){
    return{
            menus: [
          {
            type: 'Milk Tea',
            list: [
              {
                img: 'drink/matcha.jpg',
                name: 'Milk Tea',
                descriptions:'Taro Milk Tea - Matcha Milk Tea(59k) - Chocolate Milk Tea - Traditonal milk tea',
                price: '39.000',
              },
              {
                img: 'drink/milktea-G.jpg',
                name: 'G Milk Tea',
                price: '49.000',                     
                descriptions:'Proudly presented and crafted by Master-G, G-Milk Tea is made by high quality dark tea leaves with her secret formula. It does not only have a particularly pleasant aroma, but its taste also outwwighs other powder milk tea.'
              },
              {
                img: 'drink/milktea-HK.jpg',
                name: 'HK ice milk tea',
                price: '49.000',
                descriptions:'Born to number 1! Born to be the king! Sweeping to victory in Hong Kong, The origin of milk tea, De-Ice Tea beats all Taiwan milk tea chains in Hong Kong, finally landing in Vietnam.',
              },
            ],
          },
          {
            type: 'Café',
            list: [
              {
                img: 'drink/coffee.jpg',
                name: 'Milk Coffee - Black Coffee',
                descriptions:'Selected from the best quality coffee beans. Bringing the rich flavor of MindSpace quality coffee.',
                price: '49.000',
              },
            ],
          },
          {
            type: 'Tea',
            list: [
              {
                img: 'drink/tea.jpg',
                name: 'Tea',
                descriptions:'Hibicus Tea - Honey Chrysanthemum Tea - Honey green Tea - Peach Tea - red Guava Honey Tea',
                price: '39.000',
              },
            ],
          },
          {
            type: 'Topping',
            list: [
              {
                img: 'drink/topping.jpg',
                name: 'Topping',
                descriptions:' Pudding - Hạt 3Q - Thạch Ngọc Trai - Phomai</br> Strawberry - Taro -  Coffee - Lychee - Caramel - Flan Cake))',
                price: '7.000',
              },
            ],
          },
        ],
    }
}

}
</script>

<style>

</style>