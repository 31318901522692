<template>
  <section>
    <div class="contain">
      <div class="background">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ff5500"
            fill-opacity="0.6"
            d="M0,96L30,128C60,160,120,224,180,208C240,192,300,96,360,69.3C420,43,480,85,540,96C600,107,660,85,720,112C780,139,840,213,900,208C960,203,1020,117,1080,80C1140,43,1200,53,1260,64C1320,75,1380,85,1410,90.7L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
          ></path>
        </svg>
      </div>
      <section id="contact">
        <h1 class="section-header">Contact</h1>
        <div class="contact-wrapper">
          <form ref="form" @submit.prevent="sendEmail">
            <label>Name</label>
            <input type="text" id="user_name" name="user_name" />
            <label>Email</label>
            <input type="email" id="user_email" name="user_email" />
            <label>Message</label>
            <textarea name="message" id="message" rows="4"></textarea>
            <button>Send</button>
          </form>
        </div>
      </section>
      <div class="information">
        <h3>Thông Tin Liên Hệ</h3>
        <p class="">
          Hãy liên lạc với chúng tôi nếu bạn có bất kì thắc mắc nào
        </p>

        <div class="infor-detail">
          <p>
            <span>Address: </span>
            <a
              href="https://www.google.com/maps/place/100+%C4%90.+Tr%E1%BA%A7n+H%C6%B0ng+%C4%90%E1%BA%A1o,+Ph%C6%B0%E1%BB%9Dng+Ph%E1%BA%A1m+Ng%C5%A9+L%C3%A3o,+Qu%E1%BA%ADn+1,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh/@10.7654302,106.6934488,16.73z/data=!4m5!3m4!1s0x31752f16723e26b9:0xb02ca15ac61d5a35!8m2!3d10.7660734!4d106.6933469"
              target="_blank"
              >100 Trần Hưng Đạo,Phường Phạm Ngũ Lão,Quận 1</a
            >
          </p>

          <p>
            <span>Phone: </span>
            <a href="tel://0327867586">+84 327 867 586</a>
          </p>
          <p>
            <span>Email: </span>
            <a href="mailto:admin@mindspace.world">admin@mindspace.world</a>
          </p>

          <p>
            <span>Website: </span>
            <a href="#">mindspace.world</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from '@emailjs/browser';
export default {
  name: 'Contact',
  data() {
    return {};
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          process.env.VUE_APP_YOUR_SERVICE_ID,
          process.env.VUE_APP_YOUR_TEMPLATE_ID,
          this.$refs.form,
          process.env.VUE_APP_YOUR_PUBLIC_KEY
        )
        .then(
          () => {
            const user_name = document.getElementById('user_name');
            const user_email = document.getElementById('user_email');
            const message = document.getElementById('message');
            user_name.value = '';
            user_email.value = '';
            message.value = '';
            this.$toast.success(`Send Success`);
          },
          (error) => {
            this.$toast.error(`Send ${error.text}`);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped="true">
.contain {
  display: flex;
  justify-content: center;
  align-items: center;
  .background {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
  #contact,
  .information {
    width: 50%;
    padding: 2rem;
  }
  #contact {
    width: 50%;
    h1 {
      font-size: 2rem;
      text-transform: uppercase;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      input,
      textarea {
        margin: 0.5rem 0;
        width: 100%;
        border: 1px solid #d5d5d5;
        &:focus {
          outline: 1px solid #ed5d0a;
          border-color: #ed9a0a;
        }
      }
      Button {
        background-color: #ed5d0a;
        padding: 0.5rem 1rem;
        border: 2px solid #d5d5d5;
        border-radius: 8px;
        margin: 0 auto;
      }
    }
  }
  .information {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    h3 {
      font-size: 2rem;
      text-transform: uppercase;
      margin-top: 1rem;
    }
    p {
      font-size: 1.1rem;
    }
    .infor-detail {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      p {
        margin: 1rem;
        a {
          text-decoration: none;
          color: #595959;
        }
      }
    }
  }
}
@media screen and (max-width: 864px) {
  .contain {
    flex-direction: column-reverse;
    #contact,
    .information {
      width: 100%;
    }
    #contact {
      width: 100%;
    }
  }
}
</style>