<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition @leave="leave">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>
<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
  name: 'App',
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    leave: function (el, done) {
      AOS.refresh();
      done();
    },
  },
  mounted() {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 400,
      easing: 'ease',
      anchorPlacement: 'top-bottom',
    });
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,700;1,400&family=Poppins:wght@500;700&display=swap');
#app {
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
</style>
