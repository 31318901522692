<template>
  <div>
    <div class="profile" v-if="!loading">
      <div class="image-user">
        <div class="avarta" v-if="user.image_path">
          <img :src="domain_server + '/' + user.image_path" alt="" />
        </div>
        <div class="avarta" v-else>
          <img :src="require(`../../public/img/avarta.jpg`)" />
        </div>
        <div class="qr-code">
          <qrcode-vue
            level="Q"
            render-as="svg"
            :value="
              user.user_id +
              ' - ' +
              user.fist_name +
              ' ' +
              user.last_name +
              ' - ' +
              user.phone +
              ' - ' +
              user.email +
              ' - ' +
              user.address         
            "
          ></qrcode-vue>
        </div>
      </div>
      <div class="information">
        <h2>account information</h2>
        <div class="full-name">
          <div class="first-name" v-if="user.fist_name">
            {{ user.fist_name }}
          </div>
          <div class="last-name">{{ user.last_name }}</div>
        </div>
        <div class="phone-number" v-if="user.phone">
          <span>Phone:</span>
          <p>{{ user.phone }}</p>
        </div>
        <div class="address" v-if="user.address">
          <span>Address:</span>
          <p>{{ user.address }}</p>
        </div>
        <div class="email" v-if="user.email">
          <span>Email:</span>
          <p>{{ user.email }}</p>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <Loading />
    </div>
    <qrcode-vue
            render-as="png"
            size="700"
            level='L'
            value="
                 https://www.shomiptht.space    
            "
          ></qrcode-vue>
    
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import Loading from './loading.vue';

export default {
  name: 'Profile',
  components: {
    QrcodeVue,
    Loading,
  },
  data() {
    return {
      domain_server: 'https://mindspace.rejun.site',
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['getUser']),
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 300);
  },
  created() {
    this.getUser();
  },
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ff46038c;
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;

  .image-user,
  .information {
    width: 50%;
  }

  .image-user {
    position: relative;
    padding: 1rem;
    .avarta {
      width: 400px;
      height: 400px;
      overflow: hidden;
      img {
        width: 80%;
        height: 80%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .qr-code {
      border: 10px solid #d5d5d5;
      position: absolute;
      bottom: 80px;
      right: 150px;
    }
  }

  .information {
    margin-left: 1rem;

    h2 {
      text-transform: uppercase;
      color: white;
    }

    .phone-number,
    .address,
    .email,
    .birth-day {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 1rem;

      p {
        background-color: #fff;
        color: rgb(78, 78, 78);
        border-radius: 10px;
        width: 300px;
        height: 50px;
        margin: 1rem;
        line-height: 50px;
        text-align: left;
        padding: 0 1rem;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: white;
        resize: horizontal;
      }

      span {
        color: #fff;
      }
    }

    .full-name {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .first-name,
      .last-name {
        background-color: #fff;
        color: rgb(78, 78, 78);
        border-radius: 10px;
        width: 200px;
        height: 50px;
        margin: 1rem;
        line-height: 50px;
        text-align: left;
        padding: 0 1rem;
      }

      .last-name {
      }
    }
  }
}
@media screen and (max-width: 876px) {
  .profile {
    flex-direction: column;

    .image-user,
    .information {
      width: 100%;
    }

    .image-user {
      .avarta {
        width: 200px;
        height: 200px;
      }
      .qr-code {
        border: 10px solid #d5d5d5;
        position: absolute;
        bottom: 80px;
        right: 70px;
      }
    }

    .information {
      margin-left: 1rem;

      h2 {
        text-transform: uppercase;
        color: white;
      }

      .phone-number,
      .address,
      .email,
      .birth-day {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 1rem;

        p {
          background-color: #fff;
          color: rgb(78, 78, 78);
          border-radius: 10px;
          width: 300px;
          height: 50px;
          margin: 1rem;
          line-height: 50px;
          text-align: left;
          padding: 0 1rem;
        }

        span {
          color: #fff;
        }
      }

      .full-name {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .first-name,
        .last-name {
          background-color: #fff;
          color: rgb(78, 78, 78);
          border-radius: 10px;
          width: 200px;
          height: 50px;
          margin: 1rem;
          line-height: 50px;
          text-align: left;
          padding: 0 1rem;
        }

        .last-name {
        }
      }
    }
  }
}
</style>