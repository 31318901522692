<template>
  <div>
    <div v-for="(workshop, index) in workshop" :key="index">
      <section>
        <div class="infor">
          <div class="description">
            <h1>{{ workshop.name }}</h1>
          </div>
          <div class="image">
            <img
              :src="require(`../../../../public/img/workshops/${workshop.path}`)"
            />
          </div>
          
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorshopDetails',
  data() {
    return {
      workshop:[],
     workshops: [
            {
              id: 1,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 2,
              name: '24 workshops covering all topics of IELTS exams',
              path: 'workshop2.png',
            },
            {
              id: 3,
              name: 'Mock Exam practice by former IELTS examiners',
              path: 'workshop3.png',
            },
            {
              id: 4,
              name: 'Mock Exam practice by former IELTS examiners',
              path: 'workshop4.png',
            },
            {
              id: 5,
              name: 'real life conversations',
              path: 'workshop4.png',
            },
          ]
    }
  },
  methods: {
    getWordshop() {
      var search = this.$route.params.id.replace(/-/g, ' ');
      let obj = this.workshops.find((data) => data.name.toLowerCase() == search);
      this.workshop[0] = obj;
    },
  },
  async created() {
    await this.getWordshop();
  },
};
</script>

<style lang="scss" scoped>
.infor{
  .description{
    h1{
      font-size:24px;
      text-transform: uppercase;
      text-align: center;
      margin: 0 1rem;
    }

  }
  .image{
    img{
      width: 100%;
      
    }
  }
}
</style>
