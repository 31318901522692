<template>
  <section class="workshop-features">
    <div class="contain">
      <h2>Courses</h2>
      <div class="list-workshops">
        <div
          class="item-workshop"
          v-for="(course, index) in courses"
          :key="index"
          v-on:click="
            $router.push({
              name: 'CourseDetails',
              params: {
                id: course.name.toLowerCase().replace(/ /g, '-'),
              },
            })
          "
        >
          <div class="image">
            <img
              :src="require(`../../../../public/img/academy/sef/teacher/${course.image[1]}`)"
            />
          </div>
          <div class="title">
            <h3>{{ course.name }}</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'content',
  components: {},
  data() {
    return {
      courses: [
        {
          id: 1,
          name: 'ielts guarantee workshops',
          image: [
            'Promo 2022.02-04.png',
            'Promo 2022.02-06.png',
            'Promo 2022.02-08.png',
            'Promo 2022.02-10.png',
            'Promo 2022.02-14.png',
            'Promo 2022.02-16.png',
          ],
          workshops: [
            {
              id: 1,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 2,
              name: '24 workshops covering all topics of IELTS exams',
              path: 'workshop2.png',
            },
            {
              id: 3,
              name: 'Mock Exam practice by former IELTS examiners',
              path: 'workshop3.png',
            },
          ],
          description: {
            name: 'Exam Takers Achivement/ Class / Benefits',
            image: [
              'Promo 2022.02-04.png',
              'Promo 2022.02-06.png',
              'Promo 2022.02-08.png',
              'Promo 2022.02-10.png',
              'Promo 2022.02-14.png',
              'Promo 2022.02-16.png',
            ],
          },
        },
        {
          id: 2,
          name: 'super english club',
          image: [
            'Promo 2022.02-04.png',
            'Promo 2022.02-06.png',
            'Promo 2022.02-08.png',
            'Promo 2022.02-10.png',
            'Promo 2022.02-14.png',
            'Promo 2022.02-16.png',
          ],
          workshops: [
            {
              id: 2,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 1,
              name: 'Real Life Conversations',
              path: 'workshop4.png',
            },
          ],
          description: {
            name: 'Club student testmonial / Class / Benefits / Why choose us',
            image: [
              'Promo 2022.02-04.png',
              'Promo 2022.02-06.png',
              'Promo 2022.02-08.png',
              'Promo 2022.02-10.png',
              'Promo 2022.02-14.png',
              'Promo 2022.02-16.png',
            ],
          },
        },
        {
          id: 3,
          name: 'english improvement workshops',
          image: [
            'Promo 2022.02-04.png',
            'Promo 2022.02-06.png',
            'Promo 2022.02-08.png',
            'Promo 2022.02-10.png',
            'Promo 2022.02-14.png',
            'Promo 2022.02-16.png',
          ],
          workshops: [
            {
              id: 1,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 2,
              name: 'Real Life Conversations',
              path: 'workshop2.png',
            },
          ],
          description: {
            name: 'Exam Takers Achivement/ Class / Benefits',
            image: [
              'Promo 2022.02-04.png',
              'Promo 2022.02-06.png',
              'Promo 2022.02-08.png',
              'Promo 2022.02-10.png',
              'Promo 2022.02-14.png',
              'Promo 2022.02-16.png',
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  cursor: pointer;
  p {
    text-decoration: underline;
    font-weight: bold;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    margin: 1.5rem;
  }
}
.workshop-features {
  padding: 3rem 0;
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  .contain {
    .list-workshops {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .item-workshop {
        cursor: pointer;
        min-width: 300px;
        min-height: 300px;
        width: 30%;
        margin: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
          rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
          rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
          rgba(0, 0, 0, 0.07) 0px 32px 64px;
        .image {
          text-align: center;
          padding: 0.5rem;
          border-radius: 0.5rem;
          overflow: hidden;
          img {
            width: 80%;
            height: 20vw;
          }
        }
        .title {
          h3 {
            font-size: 1rem;
            text-align: center;
            padding: 0.5rem;
            display: -webkit-box;
            height: 2rem;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.625;
          }
        }
      }
    }
  }
}
</style>
