<template>
  <div>
    <header :class="{none:phone}">
      <div class="left">
        <div class="logo">
          <router-link to="/selfie-coffee">
            <img
              class="header__logo--img"
              src="../../../../public/img/icon/logo-cafe.png"
              alt=""
            />
          </router-link>
        </div>
        <div class="nav">
          <ul class="level-1">
            <li>
              <div>
                <p>Our Menu</p>
                <i class="fas fa-angle-down"></i>
              </div>
              <ul class="level-2">
                <li>
                  <router-link to="/drinks">Dinks</router-link>
                  <ul class="level-3">
                    <li>
                      <router-link to="/drinks/milk-tea">Milk Tea</router-link>
                    </li>
                    <li>
                      <router-link to="/drinks/coffee">Coffee</router-link>
                    </li>
                    <li><router-link to="/drinks/tea">Tea</router-link></li>
                    <li>
                      <router-link to="/drinks/topping">Topping</router-link>
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link to="/foods">Foods</router-link>
                  <ul class="level-3">
                    <li>
                      <router-link to="/foods/fried-foods"
                        >Fried Foods</router-link
                      >
                    </li>
                    <li><router-link to="/foods/lunch">Lunch</router-link></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <div>
                <router-link to="/news">News</router-link>
                <i class="fas fa-angle-down"></i>
              </div>
            </li>
            <li>
              <div>
                <p>Stores</p>
                <i class="fas fa-angle-down"></i>
              </div>
              <ul class="child-link" id="child3">
                <ul class="level-2">
                  <li>
                    <router-link to="/stores/HCM">HCM</router-link>
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="social-network">
          <ul class="menu-links parent-links">
            <li class="primary-links links-dropdown dropdown-socials">
              <router-link
                to="https://www.facebook.com/MindSpace41319"
                target="_blank"
                ><img src="../../../../public/img/icon/facebook.png" alt=""
              /></router-link>
            </li>
            <li>
              <router-link
                to="https://www.instagram.com/mindspace0931131914/"
                target="_blank"
                ><img src="../../../../public/img/icon/instagram.png" alt=""
              /></router-link>
            </li>
            <li>
              <router-link to="#"
                ><img src="../../../../public/img/icon/youtube.png" alt=""
              /></router-link>
            </li>
          </ul>
        </div>
        <div class="language">
          <div class="language-choose">
            <img src="../../../../public/img/icon/vietnam.png" alt="" />
          </div>
          <ul>
            <li>
              <img src="../../../../public/img/icon/vietnam.png" alt="" />
            </li>
            <li>
              <img src="../../../../public/img/icon/UK.jpg" alt="" />
            </li>
            <li>
              <img src="../../../../public/img/icon/korea.png" alt="" />
            </li>
            <li>
              <img src="../../../../public/img/icon/china.png" alt="" />
            </li>
          </ul>
        </div>
        <div class="contact">
          <router-link class="primary-links--n-bg" to="/contact"
            >liên hệ</router-link
          >
        </div>
        <div class="user-register" style="border: none">
          <div v-if="!currentUser" class="signin">
            <router-link class="primary-links--n-bg" to="/signin">
              <i class="fas fa-user"></i>
            </router-link>
          </div>
          <div v-else class="info-login">
            <span>Wellcome! {{ user.fist_name }}</span>
            <div class="log-out-info">
              <router-link class="primary-links--n-bg" to="/profile">
                Account</router-link
              >
              <button @click.prevent="handleSignout">Đăng Xuất</button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="mobile" :class="{ none: !phone }">
      <div class="left">
        <div class="logo">
          <router-link to="/selfie-coffee">
            <img
              class="header__logo--img"
              src="../../../../public/img/icon/logo-cafe.png"
              alt=""
            />
          </router-link>
        </div>
        <div class="nav">
          <ul class="level-1" :class="{ show: !thisClose }">
            <li>
              <div>
                <p>Our Menu</p>
                <i class="fas fa-angle-down"></i>
              </div>
              <ul class="level-2">
                <li>
                  <router-link to="/drinks">Dinks</router-link>
                  <ul class="level-3">
                    <li>
                      <router-link to="/drinks/milk-tea">Milk Tea</router-link>
                    </li>
                    <li>
                      <router-link to="/drinks/coffee">Coffee</router-link>
                    </li>
                    <li><router-link to="/drinks/tea">Tea</router-link></li>
                    <li>
                      <router-link to="/drinks/topping">Topping</router-link>
                    </li>
                  </ul>
                </li>
                <li>
                  <router-link to="/foods">Foods</router-link>
                  <ul class="level-3">
                    <li>
                      <router-link to="/foods/fried-foods"
                        >Fried Foods</router-link
                      >
                    </li>
                    <li><router-link to="/foods/lunch">Lunch</router-link></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <div>
                <router-link to="/news">News</router-link>
                <i class="fas fa-angle-down"></i>
              </div>
            </li>
            <li>
              <div>
                <p>Stores</p>
                <i class="fas fa-angle-down"></i>
              </div>
              <ul class="child-link" id="child3">
                <ul class="level-2">
                  <li>
                    <router-link to="/stores/HCM">HCM</router-link>
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="social-network">
          <i
            class="fa-solid fa-xmark"
            v-if="!thisClose"
            v-on:click="changeClose()"
          ></i>
          <i
            class="fa-solid fa-grip-lines"
            v-if="thisClose"
            v-on:click="changeClose()"
          ></i>
        </div>
        <div class="language">
          <div class="language-choose">
            <img src="../../../../public/img/icon/vietnam.png" alt="" />
          </div>
          <ul>
            <li>
              <img src="../../../../public/img/icon/vietnam.png" alt="" />
            </li>
            <li>
              <img src="../../../../public/img/icon/UK.jpg" alt="" />
            </li>
            <li>
              <img src="../../../../public/img/icon/korea.png" alt="" />
            </li>
            <li>
              <img src="../../../../public/img/icon/china.png" alt="" />
            </li>
          </ul>
        </div>
        <div class="contact">
          <router-link class="primary-links--n-bg" to="/contact"
            >liên hệ</router-link
          >
        </div>
        <div class="user-register" style="border: none">
          <div v-if="!currentUser" class="signin">
            <router-link class="primary-links--n-bg" to="/signin">
              <i class="fas fa-user"></i>
            </router-link>
          </div>
          <div v-else class="info-login">
            <span>Wellcome! {{ user.fist_name }}</span>
            <div class="log-out-info">
              <router-link class="primary-links--n-bg" to="/profile">
                Account</router-link
              >
              <button @click.prevent="handleSignout">Đăng Xuất</button>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
export default {
  name: 'HeaderCF',
  data() {
    return {
      close: true,
      phone: false,
    };
  },
  computed: {
    ...mapGetters(['user']),
    currentUser() {
      return this.$store.state.auth.token ? true : false;
    },
    thisClose() {
      if (this.close === true) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations(['setRemoveUser']),
    ...mapActions(['signout', 'getUser']),
    handleSignout() {
      this.setRemoveUser();
      this.signout();
      this.$router.push('/');
    },
    changeClose() {
      if (this.close == true) {
        this.close = false;
      } else {
        this.close = true;
      }
    },
  },
  created() {
    this.getUser();
    if (window.innerWidth < 865) {
      this.phone = true;
    }
    if(window.innerWidth >865){
      this.phone = false;
    }
  },
};
</script>

<style lang="scss" scoped>
header {
  position: relative;
  z-index: 9999;
  max-width: 1600px;
  background-color: black;
  &.none{
    display: none;
  }
  .bg-image {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
    img {
      transform: translate(0px, 0px);
      width: 100%;
      height: 100%;
    }
  }
  max-width: 1600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  .left {
    display: flex;
    .logo {
      img {
        height: 100px;
      }
    }
    .nav {
      ul.level-1 {
        display: flex;
        margin: 0;
        justify-content: center;
        align-items: center;
        li {
          position: relative;
          width: 150px;
          &:hover .level-2 {
            visibility: visible;
          }
          a {
            color: white;
            text-decoration: none;
          }
          .router-link-exact-active {
            text-decoration: none;
            color: white;
          }
          div {
            display: flex;
            justify-content: center;
            height: 50px;
            align-items: center;
            p {
              color: rgb(255, 255, 255);
              display: flex;
              margin: 0;
            }
            i {
              margin-left: 0.5rem;
              color: white;
            }
          }
          @keyframes moveTodown {
            0% {
              transform: translateY(120px);
              opacity: 0;
            }
            100% {
              transform: translateX(0px);
              opacity: 1;
            }
          }
          .level-2 {
            display: flex;
            flex-direction: row;
            position: absolute;
            background-color: white;
            border-radius: 3px;
            padding: 0.4rem;
            visibility: hidden;

            a {
              color: black;
              text-transform: uppercase;
              font-weight: 600;
              text-decoration: none;
            }
            li {
              display: flex;
              flex-direction: column;
              margin: 0 0.3rem;
              a {
                margin-top: 0.5rem;
                height: 50px;
                line-height: 50px;
                color: black;
              }
              .level-3 {
                padding: 0;
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
                li {
                  &:hover {
                    a {
                      color: white;
                      background-color: black;
                      border-radius: 3px;
                      border: 2px solid #d5d5d5;
                    }
                  }
                }

                li {
                  height: 50px;
                  a {
                    color: black;
                    text-transform: capitalize;
                    font-weight: 400;
                    border-bottom: 1px solid #d5d5d5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    .social-network {
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        display: flex;
        margin: 0;
        li {
          background-color: black;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: rgba(255, 255, 0, 0.63);
          }
          a {
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    .language {
      position: relative;
      margin: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;
      &:hover {
        ul {
          display: block;
        }
      }
      .language-choose {
        border-right: 2px solid #d5d5d5;
        padding-right: 0.4rem;
        img {
          width: 30px;
          height: 30px;
        }
      }
      ul {
        position: absolute;
        top: 30px;
        left: -32px;
        display: flex;
        flex-direction: column;
        display: none;
        li {
          margin-top: 0.3rem;
          &:hover {
            border-radius: 50%;
            border: 2px solid #d5d5d5;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .contact {
      background-color: black;
      border-radius: 3px;
      border: 2px solid #d5d5d5;
      height: 50px;
      width: 100px;
      a {
        color: white;
        text-decoration: none;
        line-height: 50px;
      }
    }
    .user-register {
      margin-left: 0.5rem;
      .info-login {
        position: relative;
        &:hover {
          .log-out-info {
            visibility: inherit;
          }
        }
        span {
          color: white;
        }
        .log-out-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: white;
          padding: 0.5rem;
          position: absolute;
          left: 0;
          border-radius: 5px;
          top: 30px;
          visibility: hidden;
          &::before {
            content: '';
            border: 20px solid;
            border-color: transparent transparent rgb(255, 255, 255) transparent;
            position: absolute;
            right: 0;
            top: -30px;
          }
          a {
            color: black;
            text-decoration: none;
            height: 50px;
            line-height: 50px;
          }
          button {
            border: none;
            background-color: white;
            padding-bottom: 0.5rem;
          }
          a,
          button {
            &:hover {
              color: rgb(99, 99, 99);
            }
          }
        }
      }
      .signin {
        margin-left: 1rem;
        a {
          i {
            color: white;
          }
        }
      }
    }
  }
}
header.mobile {
  position: relative;
  z-index: 9999;
  max-width: 1600px;
  background-color: black;
  width: 100vw;
  &.none{
    display: none;
  }
  .bg-image {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
    img {
      transform: translate(0px, 0px);
      width: 100%;
      height: 100%;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  .left {
    display: flex;
    .logo {
      img {
        height: 50px;
      }
    }
    .nav {
      ul.level-1 {
        display: flex;
        margin: 0;
        justify-content: center;
        align-items: center;
        width: 100vw;
        position: absolute;
        left: 0;
        top: 100px;
        transform: translateX(1600px);
        background-color: white;
        list-style: none;
        &.show {
          transform: translateX(0);
        }
        li {
          position: relative;
          width: 150px;
          &:hover .level-2 {
            visibility: visible;
          }
          a {
            color: rgb(0, 0, 0);
            text-decoration: none;
          }
          .router-link-exact-active {
            text-decoration: none;
            color: rgb(0, 0, 0);
          }
          div {
            display: flex;
            justify-content: center;
            height: 50px;
            align-items: center;
            p {
              color: rgb(0, 0, 0);
              display: flex;
              margin: 0;
            }
            i {
              margin-left: 0.5rem;
              color: white;
            }
          }
          @keyframes moveTodown {
            0% {
              transform: translateY(120px);
              opacity: 0;
            }
            100% {
              transform: translateX(0px);
              opacity: 1;
            }
          }
          .level-2 {
            display: flex;
            flex-direction: row;
            position: absolute;
            background-color: white;
            border-radius: 3px;
            padding: 0.4rem;
            visibility: hidden;
            list-style: none;

            a {
              color: black;
              text-transform: uppercase;
              font-weight: 600;
              text-decoration: none;
            }
            li {
              display: flex;
              flex-direction: column;
              margin: 0 0.3rem;
              a {
                margin-top: 0.5rem;
                height: 50px;
                line-height: 50px;
                color: black;
              }
              .level-3 {
                padding: 0;
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
                li {
                  &:hover {
                    a {
                      color: white;
                      background-color: black;
                      border-radius: 3px;
                      border: 2px solid #d5d5d5;
                    }
                  }
                }

                li {
                  height: 50px;
                  a {
                    color: black;
                    text-transform: capitalize;
                    font-weight: 400;
                    border-bottom: 1px solid #d5d5d5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    i {
      font-size: 1.5rem;
    }
    .social-network {
    }
    .language {
      position: relative;
      margin: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;
      list-style: none;
      &:hover {
        ul {
          display: block;
        }
      }
      .language-choose {
        border-right: 2px solid #d5d5d5;
        padding-right: 0.4rem;
        img {
          width: 30px;
          height: 30px;
        }
      }
      ul {
        position: absolute;
        top: 30px;
        left: -32px;
        display: flex;
        flex-direction: column;
        display: none;
        li {
          margin-top: 0.3rem;
          &:hover {
            border-radius: 50%;
            border: 2px solid #d5d5d5;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .contact {
      background-color: black;
      border-radius: 3px;
      border: 2px solid #d5d5d5;
      height: 30px;
      width: 70px;
      a {
        color: white;
        text-decoration: none;
        line-height: 30px;
        font-size: 8px;
      }
    }
    .user-register {
      margin-left: 0.5rem;
      .info-login {
        position: relative;
        &:hover {
          .log-out-info {
            visibility: inherit;
          }
        }
        span {
          color: white;
        }
        .log-out-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: white;
          padding: 0.5rem;
          position: absolute;
          left: 0;
          border-radius: 5px;
          top: 30px;
          visibility: hidden;
          &::before {
            content: '';
            border: 20px solid;
            border-color: transparent transparent rgb(255, 255, 255) transparent;
            position: absolute;
            right: 0;
            top: -30px;
          }
          a {
            color: black;
            text-decoration: none;
            height: 50px;
            line-height: 50px;
          }
          button {
            border: none;
            background-color: white;
            padding-bottom: 0.5rem;
          }
          a,
          button {
            &:hover {
              color: rgb(99, 99, 99);
            }
          }
        }
      }
      .signin {
        margin-left: 1rem;
        a {
          i {
            color: white;
          }
        }
      }
    }
  }
}
</style>
