const state = {
    language: {
        language: 'vi',
      },
}
const getters = {
 isVietnamese: state => state.language.language,
}
const mutations ={
      TOGGLE_VN(state) {
        state.language.language = 'vi';
      },
      TOGGLE_UK(state) {
        state.language.language = 'en';
      },
      TOGGLE_CH(state) {
        state.language.language = 'zh';
      },
}
export default {
    state,
    getters,
    mutations
}