<template>
  <header>
    <section>
      <div>
        <i class="fas fa-phone-alt"></i>
        <p>+84 862 310 314</p>
      </div>
      <div>
        <i class="fas fa-envelope"></i>
        <p>saiprise1314@gmail.com</p>
      </div>
    </section>
    <section>
      <nav>
        <div
          class="logo"
          v-on:click="$router.push('/')"
          style="cursor: pointer"
        >
          <img :src="require(`../../../../public/img/logo2.png`)" />
        </div>
        <div class="navbar">
          <ul :class="{show: !thisClose}" >
            <li>
              <router-link to="">Giới thiệu</router-link>
              <i class="fas fa-caret-down"></i>
              <ul style="width: 140px">
                <li>
                  <router-link to="/about">About Us</router-link>
                </li>
                <li>
                  <router-link to="/superenglishforce"
                    >Wall of Fame</router-link
                  >
                </li>
                <li>
                  <router-link to="/superenglishforce"
                    >News & Events</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/workshops">Workshops</router-link
              ><i class="fas fa-caret-down"></i>
            </li>
            <li>
              <router-link to="/courses">Khóa Học</router-link
              ><i class="fas fa-caret-down"></i>
              <ul style="width: 250px">
                <li v-for="course in courses" :key="course.id">
                  <router-link
                    :to="
                      '/courses/' + course.name.toLowerCase().replace(/ /g, '-')
                    "
                    >{{ course.name }}</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/teachers">Meet The Team</router-link
              ><i class="fas fa-caret-down"></i>
              <ul style="width: 140px">
                <li v-for="teacher in teachers" :key="teacher.id">
                  <router-link
                    :to="
                      '/teachers/' +
                      teacher.name.toLowerCase().replace(/ /g, '-')
                    "
                    >{{ teacher.name }}</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/superenglishforce">Blog </router-link
              ><i class="fas fa-caret-down"></i>
            </li>
            <li>
              <router-link to="/contact">Liên Hệ </router-link
              ><i class="fas fa-caret-down"></i>
            </li>
            <li>
              <router-link to="/superenglishforce">Hỗ trợ </router-link
              ><i class="fas fa-caret-down"></i>
            </li>
          </ul>
          <div class="icon-nav" v-if="phone">
            <i
              class="fa-solid fa-xmark"
              v-if="!thisClose"
              v-on:click="changeClose()"
            ></i>
            <i
              class="fa-solid fa-grip-lines"
              v-if="thisClose"
              v-on:click="changeClose()"
            ></i>
          </div>
          <div class="language">
            <div class="language-choose">
              <img src="../../../../public/img/icon/vietnam.png" alt="" />
            </div>
            <ul>
              <li>
                <img src="../../../../public/img/icon/vietnam.png" alt="" />
              </li>
              <li>
                <img src="../../../../public/img/icon/UK.jpg" alt="" />
              </li>
              <li>
                <img src="../../../../public/img/icon/korea.png" alt="" />
              </li>
              <li>
                <img src="../../../../public/img/icon/china.png" alt="" />
              </li>
            </ul>
          </div>
          <div class="user-register" style="border: none">
            <div v-if="!currentUser" class="signin">
              <router-link class="primary-links--n-bg" to="/signin">
                <i class="fas fa-user"></i>
              </router-link>
            </div>
            <div v-else class="info-login">
              <span>Wellcome! {{ user.fist_name }}</span>
              <div class="log-out-info">
                <router-link class="primary-links--n-bg" to="/profile">
                  Account</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </nav>
    </section>
  </header>
</template>


<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
export default {
  name: 'Header',
  data() {
    return {
      languageShow: 'UK.jpg',
      none: false,
      close: true,
      phone: false,
      courses: [
        {
          id: 1,
          name: 'IELTS Guarantee Workshops',
        },
        {
          id: 2,
          name: 'Super English Club',
        },
        {
          id: 3,
          name: 'English Improvement Workshops',
        },
      ],
      teachers: [
        {
          id: 1,
          name: 'Karson Oten',
        },
        {
          id: 2,
          name: 'Ben Field',
        },
        {
          id: 3,
          name: 'Phillip Kim',
        },
      ],
      languages: [
        {
          name: 'English',
          icon: 'UK.jpg',
        },
        {
          name: 'VietNamese',
          icon: 'vietnam.png',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['user']),
    currentUser() {
      return this.$store.state.auth.token ? true : false;
    },
    thisClose() {
      if (this.close === true) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations(['setRemoveUser']),
    ...mapActions(['signout', 'getUser']),
    handleSignout() {
      this.setRemoveUser();
      this.signout();
      this.$router.push('/');

    },
    changeClose() {
      if (this.close == true) {
        this.close = false;
      } else {
        this.close = true;
      }
    },
  },
  created() {
    this.getUser();
    if (window.innerWidth < 865) {
      this.phone = true;
    }
    if(window.innerWidth >865){
      this.phone = false;
    }
  },
};
</script>


<style lang="scss" scoped>
header {
  section {
    display: flex;
    justify-content: center;
    div {
      display: flex;
      margin: 1rem;
      align-items: center;
      i {
        margin-right: 0.5rem;
      }
      p {
        margin: 0;
      }
    }
    &:nth-child(2) {
      background-color: white;
      color: black;
      height: 50px;
    }
    nav {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .logo {
        img {
          height: 120px;
          margin-bottom: 67px;
        }
      }
      .navbar {
        display: flex;
        align-items: center;
        ul {
          list-style: none;
          display: flex;
          margin: 0;
          li + li {
            margin-left: 8px;
          }
          li {
            font-weight: bold;
            font-size: 14px;
            position: relative;
            cursor: pointer;
            a {
              color: black;
              text-decoration: none;
              &:hover {
                color: rgb(184, 184, 184);
              }
            }
            ul {
              display: flex;
              flex-direction: column;
              position: absolute;
              background-color: black;
              color: white;
              border-radius: 3px;
              display: none;
              padding-left: 5px;
              align-items: center;
              justify-content: center;
              li {
                font-size: 14px;
                height: 30px;
                margin-top: 0.5rem;
                a {
                  color: white;
                }
                & + li {
                  margin-left: 0rem;
                }
              }
            }
            i {
              margin-left: 0.5rem;
            }
            &:hover {
              color: #9e9b9b;
            }
            &:hover {
              ul {
                display: block;
              }
            }
          }
        }
        div {
          height: 50px;
        }
        .language {
          width: 50px;
          height: 50px;
          position: relative;
          margin: 0 0 0 1rem;
          &:hover {
            ul {
              visibility: visible;
            }
          }
          .language-choose {
            height: 30px;
            width: 30px;
            margin: 0;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          ul {
            position: absolute;
            left: -30;
            top: 45px;
            display: flex;
            flex-direction: column;
            padding: 0;
            visibility: hidden;
            li {
              width: 30px;
              height: 30px;
              margin-top: 4px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              &:hover {
                border-radius: 50%;
                border: 2px solid #d4d4d4;
              }
            }
            li + li {
              margin-left: 0;
            }
          }
        }
        .user-register {
          margin-left: 0.5rem;

          .info-login {
            position: relative;
            &:hover {
              .log-out-info {
                visibility: inherit;
              }
            }
            span {
              color: rgb(0, 0, 0);
            }
            .log-out-info {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              background-color: rgb(0, 0, 0);
              padding: 0.3rem;
              position: absolute;
              left: 0;
              border-radius: 5px;
              top: 30px;
              visibility: hidden;
              &::before {
                content: '';
                border: 20px solid;
                border-color: transparent transparent rgb(0, 0, 0) transparent;
                position: absolute;
                right: 0;
                top: -30px;
              }
              a {
                color: rgb(255, 255, 255);
                text-decoration: none;
                height: 50px;
                line-height: 50px;
              }
              button {
                border: none;
                background-color: rgb(0, 0, 0);
                padding-bottom: 0.5rem;
                color: white;
              }
              a,
              button {
                &:hover {
                  color: rgb(99, 99, 99);
                }
              }
            }
          }
          .signin {
            margin-left: 0.5rem;
            a {
              i {
                color: rgb(0, 0, 0);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  header {
    section {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div{
        margin: 0.5rem;
        p{
        font-size: 0.5rem;
        }
      }
      nav {
        .logo {
          img {
            height: 60px;
            margin-bottom: 10px;
          }
        }
        .navbar {
          ul {
           position: absolute;
          right: -10px;
           top:70px;
           background-color:white;
           width: 100vw;
           flex-direction: column;
           transform: translateX(-1600px);
           &.show{
           transform: translateX(0px);

           }
           li{
            margin: 0.5rem;
            ul{
              top:0;
              right: 0;

            }
           }
          }
          .icon-nav{
            font-size: 40px;
          }
          
        }
      }
    }
  }
  header section nav .navbar .user-register .info-login span{
    font-size: 4px;
  }

}
</style>