<template>
  <Menu :menus="menus" />
</template>

<script>
import Menu from '../menu.vue';
export default {
  name: 'MenuDrink',
  components: {
    Menu,
  },
  data() {
    return {
      menus: [
        {
          type: 'Fried Foods',
          list: [
            {
              img: 'food/chicken.jpg',
              name: 'Garlic Butter Wings',
              descriptions:
                'The distinctive flavor of chicken wings, with the greasy taste of butter and the aroma of garlic, will make you unable to miss it.',
              price: '168.000',
            },
            {
              img: 'food/chicken-bufalo.jpg',
              name: 'Buffalo wings',
              price: '149.000',
              descriptions:
                'Popular and typical dishes that are popular in Hong Kong',
            },
            {
              img: 'food/chicken-corn.jpg',
              name: 'Chicken Popcorns',
              price: '86.000',
              descriptions: '',
            },
            {
              img: 'food/chickent-onion-rings.jpg',
              name: 'Chicken Onion Rings',
              price: '68.000',
              descriptions: '',
            },
            {
              img: 'food/chicken-corn.jpg',
              name: 'Cheese Bacon Fries',
              price: '86.000',
              descriptions: '',
            },
          ],
        },
        {
          type: 'Lunch',
          list: [
            {
              img: 'food/burger.jpg',
              name: 'Wagyu Beef Burger',
              descriptions:
                '  Wagy beef is selected from a reputable and quality farm. Delicious fresh meat mixed with vegetables with special sauce brings quality taste',
              price: '149.000',
            },
            {
              img: 'food/noodle.jpg',
              name: 'Onsen Egg Spaghetti Carbonara',
              descriptions: '',
              price: '149.000',
            },
            {
              img: 'food/combo.jpg',
              name: 'MinD Space Big Combo',
              descriptions: '',
              price: '186.000',
            },
          ],
        },
      ],
    };
  },
};
</script>

<style>
</style>