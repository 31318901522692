import { createRouter, createWebHistory } from 'vue-router';
import MSLayout from '../components/MindSpace/MSlayouts/MSLayout.vue';
import Home from '../components/MindSpace/Home.vue';
import Contact from '../components/contact.vue';
import Signup from '../components/Auth/Signup.vue';
import Signin from '../components/Auth/Signin.vue';
import Profile from '../components/profile.vue'

import CFLayout from '../components/Coffee/CFLayouts/CFLayout.vue';
import CFHome from '../components/Coffee/CFHome.vue';
import News from '../components/Coffee/News.vue';
import StoreHCM from '../components/Coffee/Stores/HCM.vue';
import MenuDrinks from '../components/Coffee/Drinks/menuDrink.vue';
import descriptionDrinks from '../components/Coffee/Drinks/descriptionDrinks.vue';
import MenuFoods from '../components/Coffee/Foods/menuFoods.vue';
import descriptionFoods from '../components/Coffee/Foods/descriptionFoods.vue';

import ACLayout from '../components/Academy/AClayouts/ACLayout.vue';
import Sef from '../components/Academy/Sef.vue';
import About from '../components/Academy/About/About.vue'
import Courses from '../components/Academy/Courses/Courses.vue';
import CourseDetails from '../components/Academy/Courses/coursedetails.vue';
import Teachers from '../components/Academy/Teachers/Teachers.vue';
import TeacherDetails from '../components/Academy/Teachers/TeacherDetails.vue';
import ListWorkshops from '../components/Academy/Workshops/ListWorkshop.vue';
import WorkshopDetails from '../components/Academy/Workshops/WorkshopDetail.vue';
import Notfound from '../components/NotFound/NotFound.vue';

import Loading from '../components/loading.vue'
const routes = [
  {
    path: '/',
    name: 'MSLayout',
    component: MSLayout,
    children: [
      {
        path:'',
        name:'Home',
        component: Home
      },
      {
        path:'loading',
        name:'Loading',
        component: Loading
      },
      {
        path:'contact',
        name:'Contact',
        component: Contact
      },
      {
        path:'profile',
        name:'Profile',
        component: Profile
      },
      {
        path:'signin',
        name:'Signin',
        component: Signin
      },
      {
        path:'signup',
        name:'Signup',
        component: Signup
      },
    ]
  },
  {
    path: '/selfie-coffee',
    name: 'CFLayout',
    component: CFLayout,
    children: [
      {
        path:'',
        name:'CFHome',
        component: CFHome
      },
      {
        path:'/news',
        name:'News',
        component: News
      },
      {
        path:'/stores/HCM',
        name:'StoreHCM',
        component: StoreHCM
      },
      {
        path:'/drinks',
        name:'MenuDrinks',
        component: MenuDrinks
      },
      {
        path:'/drinks/:name',
        name:'DescriptionDrinks',
        component: descriptionDrinks
      },
      {
        path:'/foods',
        name:'MenuFoods',
        component: MenuFoods
      },
      {
        path:'/foods/:name',
        name:'DescriptionFoods',
        component: descriptionFoods
      },
    ]
  },
  {
    path: '/superenglishforce',
    name: 'ACLayout',
    component: ACLayout,
    children: [
      {
        path:'',
        name:'Sef',
        component:Sef
      },
      {
        path:'/about',
        name:'About',
        component:About
      },
      {
        path: '/courses',
        name: 'Courses',
        component: Courses,
      },
      {
        path: '/courses/:id',
        name: 'CourseDetails',
        component: CourseDetails,
      },
      {
        path: '/teachers',
        name: 'Teachers',
        component: Teachers,
      },
      {
        path: '/teachers/:id',
        name: 'TeacherDetails',
        component: TeacherDetails,
      },
      {
        path: '/workshops',
        name: 'ListWorkshops',
        component: ListWorkshops,
      },
      {
        path: '/workshops/:id',
        name: 'WorkshopDetails',
        component: WorkshopDetails,
      },
    ]
  }, 
];
const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHistory(),
  routes: [
    ...routes,
    {
      path: '/:catchAll(.*)',
      component: Notfound,
      name: 'Notfound',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') === null) {
      next({
        path: '/signin',
        params: { nextUrl: to.fullPath },
      });
    } else {
      let user = JSON.parse(localStorage.getItem('user'));
      if (to.matched.some((record) => record.meta.role)) {
        if (user.role === 'admin') {
          next();
        } else {
          next({ name: 'home' });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem('token') === null) {
      next();
    } else {
      next({ name: 'home' });
    }
  } else {
    next();
  }
});

export default router;
