<template>
  <div>
    <div class="sef-content" v-if="!loading">
      <div class="image1"></div>
      <div class="container content">
        <section>
          <div class="container banner">
            <div class="voucher" data-aos="flip-down">
              <img
                :src="require(`../../../public/img/voucher-course/voucher.jpg`)"
              />
            </div>

            <div class="button-register">
              <p data-aos="fade-left">
                Nhấn đăng kí để nhận ưu đãi khóa học ngay hôm nay
              </p>
              <button v-on:click="$router.push('/signup')">Đăng Ký</button>
            </div>
          </div>
        </section>
        <section class="introduce">
          <h1
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-offset="0"
            data-aos-delay="0"
          >
            Who are Super English Force (SEF)?
          </h1>
          <h2
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-offset="0"
            data-aos-delay="100"
          >
            We are a group of teachers from around the world who just happened
            to share the same vision and now gather together in Vietnam. Each
            one of our teacher is equipped with deliverying a life time of
            experience that will help you to reach new heights and succeed in
            any mission given to you by work or social status.
          </h2>
        </section>
        <section class="container rorate">
          <Carousel
            :itemsToShow="1"
            :wrapAround="true"
            :autoplay="6000"
            :transition="3000"
          >
            <Slide v-for="(rorate, index) in rorates" :key="index">
              <div class="contain2">
                <div class="img">
                  <img :src="require(`../../../public/img/rorate/${rorate}`)" />
                </div>
              </div>
            </Slide>
            <template #addons>
              <pagination />
            </template>
          </Carousel>
        </section>
        <section class="banner-teacher">
          <img
            src="../../../public/img/academy/sef/SEF-BANNER-VIE.jpg"
            alt=""
          />
        </section>
        <section class="workshop">
          <div class="courses">
            <h1>Workshop & Clubs</h1>
            <div class="list-course">
              <div
                class="course"
                v-for="(contentw, key) in contentws.slice(0, 2)"
                :key="key"
              >
                <div class="image">
                  <img
                    :src="
                      require(`../../../public/img/workshops/${contentw.img}`)
                    "
                  />
                </div>
                <div class="description">
                  <h2>
                    {{ contentw.name }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="sign-up">
          <div class="contain">
            <section id="contact">
              <h1 class="section-header">Contact</h1>
              <div class="contact-wrapper">
                <form ref="form" @submit.prevent="sendEmail">
                  <label>Name</label>
                  <input type="text" id="user_name" name="user_name" />
                  <label>Email</label>
                  <input type="email" id="user_email" name="user_email" />
                  <label>Message</label>
                  <textarea name="message" id="message" rows="4"></textarea>
                  <button>Send</button>
                </form>
              </div>
            </section>
            <div class="image">
              <img :src="require(`../../../public/img/cafe-mindspace.jpg`)" />
            </div>
          </div>
        </section>
      </div>
    </div>
    <div v-if="loading">
      <Loading />
    </div>
  </div>
</template>

<script>
import Loading from '../loading.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import emailjs from '@emailjs/browser';
export default {
  name: '',
  components: {
    Carousel,
    Slide,
    Pagination,
    Loading,
  },
  data() {
    return {
      loading: true,
      name: '',
      email: '',
      message: '',
      rorates: [
        '1.jpg',
        '2.jpg',
        '3.jpg',
        '4.jpg',
        '12.jpg',
        '5.jpg',
        '6.jpg',
        '7.jpg',
        '13.jpg',
        '8.jpg',
        '9.jpg',
        '11.jpg',
      ],
      studentsSucess: [
        {
          img: '4827.jpg',
          name: 'AnNa',
          course: 8.0,
        },
        {
          img: '5442.jpg',
          name: 'AnNa',
          course: 8.0,
        },
        {
          img: '9960.jpg',
          name: 'AnNa',
          course: 8.0,
        },
        {
          img: '9961.jpg',
          name: 'AnNa',
          course: 8.0,
        },
        {
          img: '9962.jpg',
          name: 'AnNa',
          course: 8.0,
        },
        {
          img: '9963.jpg',
          name: 'AnNa',
          course: 8.0,
        },
        {
          img: '9964.jpg',
          name: 'AnNa',
          course: 8.0,
        },
        {
          img: '9965.jpg',
          name: 'AnNa',
          course: 8.0,
        },
        {
          img: '9966.jpg',
          name: 'AnNa',
          course: 8.0,
        },
        {
          img: '99612.jpg',
          name: 'AnNa',
          course: 8.0,
        },
      ],
      contentws: [
        {
          name: 'Presents: Dilemma',
          img: 'workshop5.jpg',
        },
        {
          name: 'Presents: Bad Habits',
          img: 'workshop6.jpg',
        },
      ],
    };
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          process.env.VUE_APP_YOUR_SERVICE_ID,
          process.env.VUE_APP_YOUR_TEMPLATE_ID,
          this.$refs.form,
          process.env.VUE_APP_YOUR_PUBLIC_KEY
        )
        .then(
          () => {
            const user_name = document.getElementById('user_name');
            const user_email = document.getElementById('user_email');
            const message = document.getElementById('message');
            user_name.value = '';
            user_email.value = '';
            message.value = '';
            this.$toast.success(`Send Success`);
          },
          (error) => {
            this.$toast.error(`Send ${error.text}`);
          }
        );
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.sef-content {
  .image1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    scroll-behavior: smooth;
    position: sticky;
    img {
      height: 120vh;
      object-fit: contain;
    }
  }
}
.container {
  max-width: 1600px;
}
section {
  margin-bottom: 1rem;
}
.content {
  max-width: 2000px;
  padding: 0;
  .contain {
    display: flex;
    width: 100%;
    justify-content: center;
    .img {
      width: 50%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .description {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }
  .introduce {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background-color: white;
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1rem;
      text-align: center;
      padding: 0 2rem;
      font-weight: 400;
    }
  }
  .rorate {
    width: 100%;
    .contain2 {
      width: 100%;
      height: 50vw;
      background-color: #d5d5d5;
      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
  }
  .banner-teacher {
    img {
      width: 100%;
    }
  }
  .workshop {
    display: flex;
    justify-content: center;
    background-color: #fafafae3;
    .courses {
      h1 {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        margin-top: 1rem;
      }
      .list-course {
        display: flex;
        flex-wrap: wrap;
        max-width: 1200px;
        .course {
          overflow: hidden;
          margin: 0.5rem;
          border-radius: 5px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          padding: 0.5rem;
          .image {
            width: 30%;
            img {
              width: 300px;
              object-fit: cover;
            }
          }
          .description {
            width: 100%;
            text-align: center;
            padding-top: 0.5rem;
            h2 {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
  .sign-up {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .contain {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .image {
        height: 50vw;
        width: 50%;
        img {
          height: 100%;
          width: 100%;
        }
      }
      #contact {
        width: 50%;
        padding: 2rem;
        h1 {
          font-size: 2rem;
          text-transform: uppercase;
        }
        form {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          input,
          textarea {
            margin: 0.5rem 0;
            width: 100%;
          }
          Button {
            background-color: #ed5d0a;
            padding: 0.5rem 1rem;
            border: 2px solid #d5d5d5;
            border-radius: 8px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
.container .banner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  background-color: black;
  .voucher {
    width: 50%;
    img {
      width: 100%;
    }
  }
  .button-register {
    width: 50%;
    p {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      color: white;
    }
    button {
      background-color: #ed5d0a;
      color: white;
      padding: 0.7rem;
      border-radius: 8px;
      border: 2px solid #ed5d0a5c;
      animation: glowing 1300ms infinite;
      cursor: pointer;
      -webkit-border-radius: 60px;
      @keyframes glowing {
        0% {
          background-color: #ed5d0a;
          box-shadow: 0 0 5px #ed5d0a;
          transform: scale(1.1);
        }
        50% {
          background-color: #e28f5f;
          box-shadow: 0 0 20px #e28f5f;
          transform: scale(1);
          border-color: transparent;
        }
        100% {
          background-color: #ed5d0a;
          box-shadow: 0 0 5px #ed5d0a;
          transform: scale(1.1);
        }
      }
    }
  }
}
@media screen and (max-width: 786px) {
  section {
    margin-top: 2rem;
    .container .banner {
      margin-top: 3rem;
    }
    &.introduce {
      margin: 0px 0;
    }
  }

  .content {
    max-width: 2000px;
    padding: 0;
    .contain {
      display: flex;
      width: 100%;
      justify-content: center;
      .img {
        width: 50%;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .description {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
      }
    }
    .introduce {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50vh;
      background-color: white;
      h1 {
        font-size: 1rem;
      }
      h2 {
        font-size: 0.8rem;
        text-align: center;
        padding: 0 2rem;
        font-weight: 400;
      }
    }
    .rorate {
      width: 100%;
      .contain2 {
        width: 100%;
        height: 50vw;
        background-color: #d5d5d5;
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
    }
    .banner-teacher {
      img {
        width: 100%;
      }
    }
    .workshop {
      flex-direction: column;
      align-items: center;
      .courses {
        h1 {
          font-size: 1rem;
          margin-top: 0.5rem;
        }
        .list-course {
          display: flex;
          flex-direction: column;
          .description {
            width: 100%;
            text-align: center;
            padding-top: 0.5rem;
            h2 {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
    .sign-up {
      .contain {
        flex-direction: column;
        .image {
          height: 100vw;
          width: 100vw;
          img {
            height: 100%;
            width: 100%;
          }
        }
        #contact {
          width: 100%;
        }
      }
    }
  }
  .container .banner {
    .button-register {
      p {
        font-size: 8px;
      }
      button {
        width: 60px;
        height: 30px;
        font-size: 8px;
        line-height: 0px;
      }
    }
  }
}
</style>
