<template>
  <div class="layout-user">
    <Header class="header"/>
    <div class="main-content">
      <router-view v-slot="{ Component }">
        <transition name="box-fade">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <Footer class="footer"/>
  </div>
</template>

<script>
import Header from './Header.vue';
import Footer from './Footer.vue';
export default {
  name: 'MSUser',
  components: {
    Header,
    Footer,
  },
  data(){
    return {
    }
  },
};
</script>

<style lang= "scss" scoped>
.layout-user{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // background-color: black;?
  .header{
    background-color:black;
    width: 100vw;
  }
}
.main-content {
  position: relative;
  // padding-top: 120px;
  min-height: 70vh;
  width: 100%;
}
.box-fade-enter-active {
  transition: all 0.3s ease-out;
}

.box-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.box-fade-enter-from,
.box-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
@media only screen and (max-width: 896px) and (min-width: 320px) {
  .main-content {
    padding-top: 4rem;
  }
}
</style>
