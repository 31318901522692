<template>
  <section>
    <h1
      class="main-title"
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1500"
      data-aos-offset="0"
    >
      Cafe Grand Openings
    </h1>
    <div class="contain">
      <div
        class="image"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1500"
        data-aos-offset="0"
        data-aos-delay="100"
      >
        <img src="img/counpons/1.jpg" alt="" />
      </div>
      <div class="description">
        <ol>
          <li
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-offset="0"
            data-aos-delay="100"
          >
            Register to be our member to get one 50K voucher.
          </li>
          <li
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-offset="0"
            data-aos-delay="200"
          >
            Member to bring your friend to our cafe on May 2nd! You can got
            voucher for free.
          </li>
          <li
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-offset="0"
            data-aos-delay="300"
          >
            Bring one (friend) get one (voucher) free; bring more get more for
            free.
          </li>
          <li
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-offset="0"
            data-aos-delay="400"
          >
            Voucher is life-time validity.
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  padding: 1rem;
  .main-title {
    color: white;
    border: 4px solid #d5d5d5;
    border-radius: 10px;
    text-align: center;
    padding: 0.5rem;
    margin-right: auto;
    font-size: 1.5rem;
    width: 50vw;
  }
  .contain {
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      width: 50%;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .description {
      width: 50%;
      color: white;
      text-align: left;
    }
  }
}
@media screen and (max-width: 846px) {
  section {
    padding: 0.5rem;
    .contain {
      flex-direction: column;
      .image {
        width: 100%;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .description {
        margin-top: 3rem;
        width: 100%;
        color: white;
        text-align: left;
      }
    }
  }
}
</style>