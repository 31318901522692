<template>
  <section>
    <h1
      class="main-title"
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="1500"
      data-aos-offset="0"
    >
      Selfie Cafe Trần Hưng Đạo
    </h1>
    <p></p>
    <div class="contain">
      <ul class="information">
        <li
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos-offset="0"
          data-aos-delay="100"
        >
          Address:

          <a
            href="https://www.google.com/maps/place/100+%C4%90.+Tr%E1%BA%A7n+H%C6%B0ng+%C4%90%E1%BA%A1o,+Ph%C6%B0%E1%BB%9Dng+Ph%E1%BA%A1m+Ng%C5%A9+L%C3%A3o,+Qu%E1%BA%ADn+1,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh/@10.7660734,106.6933469,17z/data=!3m1!4b1!4m5!3m4!1s0x31752f16723e26b9:0xb02ca15ac61d5a35!8m2!3d10.7660734!4d106.6933469"
            >100 Trần Hưng Đạo, Quận 1</a
          >
        </li>
        <li
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos-offset="0"
          data-aos-delay="200"
        >
          Hotline: <a href="tel:84327867586">0327 867 586</a>
        </li>
        <li
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos-offset="0"
          data-aos-delay="300"
        >
          Email:
          <a href="mailto:saiprise1314@gmail.com">Saiprise1314@gmail.com</a>
        </li>
      </ul>

      <div
        class="map"
        data-aos="zoom-in"
        data-aos-easing="linear"
        data-aos-duration="500"
        data-aos-offset="0"
        data-aos-delay="0"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.5896690527793!2d106.6933469!3d10.7660734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f16723e26b9%3A0xb02ca15ac61d5a35!2zMTAwIMSQLiBUcuG6p24gSMawbmcgxJDhuqFvLCBQaMaw4budbmcgUGjhuqFtIE5nxakgTMOjbywgUXXhuq1uIDEsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaA!5e0!3m2!1svi!2s!4v1651299876880!5m2!1svi!2s"
          width="100%"
          height="100%"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang ='scss' scoped>
section {
  padding: 1rem;
  .main-title {
    background-color: black;
    color: white;
    border: 4px solid #d5d5d5;
    border-radius: 10px;
    margin-right: auto;
    padding: 0.5rem;
    font-size: 1.5rem;
    width: 50vw;
  }
  .contain {
    .information {
      color: white;
      list-style: none;
      text-align: left;
      li {
        margin: 1rem 0.5rem;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
    .map {
      height: 50vh;
    }
  }
}
@media screen and(max-width: 768px) {
  section {
  .contain {
    .information {
   padding-left: 0;
      
    }
  }
}
}
</style>