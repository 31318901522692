import { createStore } from 'vuex';
import authModule from './modules/auth.module';
import profile from './modules/profile.module.js';
import language from './modules/language.module.js';

const store = createStore({
  modules: {
    profile,
    language,
    auth: {
      ...authModule,
    },
  },
  getters: {},
  mutations: {},
  actions: {},
});
export default store;
