<template>
  <footer class="container-footer">
    <div class="container">
      <div class="content">
        <div class="selfiecoffee">
          <h2>MinD Space</h2>
          <ul>
            <li>Hotline: <a href="tel://+84327867586">(+84) 327 867 586</a></li>
            <li>
              Email:
              <a href="mailto:saiprise1314@gmail.com">
                mindspace319319@gmail.com</a
              >
            </li>
          </ul>
        </div>
        <div class="address">
          <h2>Our Store</h2>
          <ul>
            <li>
              Address:
              <a
                href="https://www.google.com/maps/place/100+%C4%90.+Tr%E1%BA%A7n+H%C6%B0ng+%C4%90%E1%BA%A1o,+Ph%C6%B0%E1%BB%9Dng+Ph%E1%BA%A1m+Ng%C5%A9+L%C3%A3o,+Qu%E1%BA%ADn+1,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh/@10.7660734,106.6911582,17z/data=!3m1!4b1!4m5!3m4!1s0x31752f16723e26b9:0xb02ca15ac61d5a35!8m2!3d10.7660734!4d106.6933469"
                target="_blank"
                >100 Trần Hưng Đạo, Quận 1, Thành phố Hồ Chí Minh
              </a>
            </li>
          </ul>
        </div>
        <div class="policy">
          <h2>privacy policy</h2>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style  lang="scss" scoped>
.container-footer {
  display: flex;
  background-color: black;
  width: 100%;
  .container {
    max-width: 1600px;
    padding: 1rem;
    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      div {
        width: calc(100% / 3);
        color: white;
        h2 {
          font-size: 1.2rem;
        }
        ul {
          list-style: none;
          text-align: left;
          li {
            a {
              color: white;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:745px) {
  .container-footer {
  .container {

    .content {
      flex-direction: column;
      div {
        width: 100%;
        text-align:left;
      }
    }
  }
}}
</style>