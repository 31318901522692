import { createApp} from 'vue';
import Main from './Main.vue';
import router from './routes';
import store from './stores/store';
import 'bootstrap/dist/css/bootstrap.css';
import dotenv from 'dotenv';
import Toaster from "@meforma/vue-toaster";
dotenv.config();


const app = createApp(Main);
app.use(router);
app.use(store);
app.use(Toaster,{   
position: "top-right",
duration: 4000,     
});
app.mount('#app');
