<template>
  <Suspense>
    <div>
      <div class="layout-user">
        <Header />
        <div class="main-content">
          <router-view v-slot="{ Component }">
            <transition name="box-fade">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
        <Footer class="footer" />
      </div>
    </div>
    <template #fallback>
      <Loading />
    </template>
  </Suspense>
</template>

<script>
import Header from './Header.vue';
import Footer from './Footer.vue';
import Loading from '../../loading.vue';
export default {
  name: 'MSUser',
  components: {
    Header,
    Footer,
    Loading,
  },
  data() {
    return {};
  },
};
</script>

<style lang= "scss" scoped>
.layout-user {
  // background: url(https://preview.colorlib.com/theme/coffeeblend/images/xbg_4.jpg.pagespeed.ic.jZaRfvTz2Z.webp)
    // no-repeat fixed;
    background-color: black;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-content {
  position: relative;
  width: 100%;
  min-height: 70vh;
}
.box-fade-enter-active {
  transition: all 0.3s ease-out;
}

.box-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.box-fade-enter-from,
.box-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
@media only screen and (max-width: 896px) and (min-width: 320px) {
}
</style>
