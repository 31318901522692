<template>
  <div>
    <div v-for="(Teacher, index) in Teacher" :key="index">
      <section>
        <div class="infor">
          <div class="image">
            <img
              :src="require(`../../../../public/img/teacher/${Teacher.image}`)"
            />
          </div>
          <div class="description">
            <h1>{{ Teacher.name }}</h1>
            <p>{{ Teacher.description }}</p>
          </div>
        </div>
      </section>
      <Workshops :workshops="Teacher.workshops" />
    </div>
  </div>
</template>

<script>
import Workshops from '../Workshops/workshops.vue';
export default {
  name: 'CourseDetails',
  components: { Workshops },
  data() {
    return {
      Teacher: [],
      Teachers: [
        {
          id: 1,
          name: 'Karson Oten',
          image: 'Karson Oten.png',
          description:
            'Well-known Hong Kong Star English Teacher, with No.1 student base over 100,000 in the past 20 years (1 in 70 Hong Kong citizens ever taught), named as ‘Tutor God’ by CNN, Top 1 in Asia (2006-2018). Youngest principal from Hong Kong, Prime mover of the 3 out of Big 4 private education groups in Hong Kong from 2002 to 2018 including Modern Education (1082) listing on HKEX Main Board (at the value of $USD 113 million)',
          workshops: [
            {
              id: 1,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 2,
              name: '24 workshops covering all topics of IELTS exams',
              path: 'workshop2.png',
            },
            {
              id: 3,
              name: 'Mock Exam practice by former IELTS examiners',
              path: 'workshop3.png',
            },
          ],
        },
        {
          id: 2,
          name: 'Ben Field',
          image: 'Ben Field.png',
          description:
            'Netflix scriptwriter. Considerable professional experience in teaching all levels of students in the United Kingdom and also in Vietnam, Ben is our British Star Tutor who can incorporate art, photography, music and movies seamlessly into English teaching, arousing students’ learning interest and helping them with practical usage.',
          workshops: [
            {
              id: 1,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 2,
              name: '24 workshops covering all topics of IELTS exams',
              path: 'workshop2.png',
            },
            {
              id: 3,
              name: 'Mock Exam practice by former IELTS examiners',
              path: 'workshop3.png',
            },
          ],
        },
        {
          id: 3,
          name: 'Phillip Kim',
          image: 'Phillip Kim.png',
          description:
            'Born in America, Phillip grows up in a native English environment and studied abroad in his early life, leading the whole team of teaching assistants in international schools. Our Korean Star English Tutor has unrivalled experience in teaching all kinds of students especially Korean students who wants to improve English proficiency in a short period of time.',
          workshops: [
            {
              id: 2,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 1,
              name: 'Real Life Conversations',
              path: 'workshop4.png',
            },
          ],
        },
      ],
    };
  },
  methods: {
    getTeacher() {
      var search = this.$route.params.id.replace(/-/g, ' ');
      let obj = this.Teachers.find((data) => data.name.toLowerCase() == search);
      this.Teacher[0] = obj;
    },
  },
  async created() {
    await this.getTeacher();
  },
};
</script>

<style lang="scss" scoped>
.infor {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(97, 226, 37);
  .image {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    img {
      width: 80%;
      object-fit: cover;
    }
  }
  .description {
    width: 50%;
    padding: 2rem;
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
}
</style>
