<template>
  <div>
    <section class="banner-teacher">
      <img src="../../../../public/img/academy/sef/SEF-BANNER-VIE.jpg" alt="" />
    </section>
    <section>
      <h1
        data-aos="fade-up"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        ata-aos-duration="1000"
      >
        About US
      </h1>
      <p
        data-aos="fade-up"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        data-aos-duration="2000"
      >
        Super English Force (SEF) established since 1998 by Dr Karson Oten. His
        lifetime goal is to invent a way for people to freely communicate
        through English. The way that he invented is by creating different
        teaching modes and educational workshops for English leaners. With the
        creations, SEF in English learning filed is known for teaching
        efficiency and learning effectiveness. In 2022, SEF forms a group of
        teachers sharing the same vision and brings the experience and learning
        methods to Vietnam. If you would like to learn English in an effective
        way, which has been approved functional in China, Korea, England and
        Taiwan, please contact us.
      </p>
    </section>
    <section>
      <h2>SEF Achivements in Hong Kong</h2>
      <Carousel
        :itemsToShow="1"
        :wrapAround="true"
        :autoplay="3000"
        :transition="3000"
        :breakpoints="breakpoints"
      >
        <Slide v-for="(sefHK, index) in sefHKs" :key="index">
          <div class="contain">
            <div class="img">
              <img
                :src="
                  require(`../../../../public/img/academy/sef/class/${sefHK.img}`)
                "
              />
            </div>
          </div>
        </Slide>
        <template #addons>
          <pagination />
        </template>
      </Carousel>
      <div></div>
    </section>
    <section>
      <h2>Our Academy Partners</h2>
      <div class="academic-partners">
        <div
          class="partner-item"  
        >
          <a href="https://www.hamilton.edu/" target="_blank">
            <img
              src="https://www.hamilton.edu/assets/images/logo-print.png"
              alt=""
            />
          </a>
        </div>
        <div
           class="partner-item"  
        >
          <a href="https://www.unimelb.edu.au/" target="_blank">
            <img
              src="https://d2glwx35mhbfwf.cloudfront.net/v13.0.0/logo-with-padding.svg"
              alt=""
            />
          </a>
        </div>
        <div
          class="partner-item"
          
        >
          <a href="https://www.columbia.edu/" target="_blank">
            <img
              src="https://www.columbia.edu/content/themes/custom/columbia/assets/img/cu-header.svg"
              alt=""
            />
          </a>
        </div>
        <div
          class="partner-item"
          
        >
          <a href="https://www.sheffield.ac.uk/" target="_blank">
            <img
              src="https://www.sheffield.ac.uk/themes/custom/uos/images/logos/uos-crest.svg"
              alt=""
            />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination } from 'vue3-carousel';
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      sefHKs: [
        {
          img: 'Promo 2022.02-17.png',
        },
        {
          img: 'Promo 2022.02-19.png',
        },
        {
          img: 'Promo 2022.02-21.png',
        },
        {
          img: 'Promo 2022.02-23.png',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  margin-bottom: 1rem;
}
.banner-teacher {
  img {
    width: 100%;
  }
}
h1,
h2 {
  font-size: 1.5em;
  width: 100%;
  text-align: center;
  font-weight: 700;
}
p {
  text-align: justify;
  padding: 0 2rem;
  line-height: 2;
  spacing: 2px;
}
.contain {
  .img {
    width: 100vw;
    height: 50vw;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
.academic-partners {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .partner-item {
    width: 20%;
    margin: 2.5%;
    &:nth-child(3) {
      background-color: rgb(80, 117, 227);
      img {
        padding: 0.8rem;
      }
    }
    a {
      text-decoration: none;
      img {
        width: 100%;
      }
    }
  }
}
</style>
