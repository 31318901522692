<template>
  <section class="main-home-page">
    <section
      class="bg-image"
      v-if="!loading"
      data-aos-easing="linear"
      data-aos-duration="10000"
      data-aos="zoom-out"
      data-aos-offset="0"
      data-aos-delay="300"
    >
      <img
        class="header__logo--img"
        src="../../../public/img/space.jpg"
        alt=""
      />
    </section>
    <div class="container" v-if="!loading">
      <ul class="list">
        <li
          class=""
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos="zoom-in"
          data-aos-offset="0"
          data-aos-delay="400"
        >
          <div class="overlay">
            <div class="button"><p>Comming Soon</p></div>
          </div>
          <a href="#">
            <div class="image">
              <img src="img/detail/studio3.jpeg" alt="studio" />
            </div>
            <h2>Studio</h2>
          </a>
        </li>
        <li
          class=""
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos="zoom-in"
          data-aos-offset="0"
          data-aos-delay="300"
        >
          <div class="overlay">
            <div class="button"><p>Comming Soon</p></div>
          </div>
          <a href="#">
            <div class="image">
              <img src="img/detail/performance2.jpg" alt="performance" />
            </div>
            <h2>Performance</h2>
          </a>
        </li>
        <li
          class=""
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos="zoom-in"
          data-aos-offset="0"
          data-aos-delay="300"
        >
          <router-link to="/superenglishforce">
            <div class="image">
              <img src="img/detail/academy4.jpeg" alt="Academy" />
            </div>
            <h2>Academy</h2>
          </router-link>
        </li>
        <li
          class=""
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos="zoom-in"
          data-aos-offset="0"
          data-aos-delay="300"
        >
          <router-link to="/selfie-coffee">
            <div class="image">
              <img src="img/detail/bar-coffee.jpg" alt="cafe" />
            </div>
            <h2>CAFE</h2>
          </router-link>
        </li>
        <li
          class=""
          data-aos-easing="linear"
          data-aos-duration="1500"
          data-aos="zoom-in"
          data-aos-offset="0"
          data-aos-delay="300"
        >
          <div class="overlay">
            <div class="button"><p>Comming Soon</p></div>
          </div>
          <a href="#">
            <div class="image">
              <img src="img/detail/event3.jpg" alt="events" />
            </div>
            <h2>EvENT</h2>
          </a>
        </li>
      </ul>
    </div>
    <Loading v-if="loading" />
  </section>
</template>
<script>
import AOS from 'aos';
import Loading from '../loading.vue';
export default {
  name: 'HomeMS',
  components: {
    Loading,
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 300);
  },
  destroyed() {
      AOS.refresh();
  },
};
</script>

<style lang="scss" scoped>
.main-home-page {
  .bg-image {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: -150px;
    left: 0;
    object-fit: cover;
    z-index: -1;
    img {
      transform: translate(0px, 0px);
      width: 100%;
      height: 100%;
    }
  }
  ul {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    li {
      .overlay {
        width: calc(100vw / 6);
        height: calc(100vw / 6);
        position: absolute;
        background-color: rgba(0, 0, 0, 0.302);
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          .button {
            display: block;
          }
        }
        .button {
          width: calc(100% / 2);
          height: 30px;
          background-color: #dedede;
          color: rgb(0, 0, 0);
          border: 2px solid #3734344b;
          padding: 0.5em;
          font-size: 0.8rem;
          border-radius: 3px;
          display: none;
        }
      }
      width: calc(100vw / 6);
      height: calc(100vw / 6);
      position: relative;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 30px;
        animation: flicker 1.5s infinite alternate;
        color: rgb(0, 0, 0);
        text-decoration: none;
        .image {
          width: 100%;
          height: calc(100vw / 6);
          img {
            width: 100%;
            height: 100%;
            padding: 0.5rem;
            object-fit: cover;
          }
        }
        h2 {
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
  @keyframes flicker {
    0%,
    18%,
    22%,
    25%,
    53%,
    57%,
    100% {
      text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff,
        0 0 40px rgb(0, 0, 0), 0 0 80px #000, 0 0 90px #000, 0 0 100px #000,
        0 0 150px #000;
    }
  }
}
@media screen and (max-width: 768px) {
  .main-home-page {
    ul {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      overflow-x: auto;
      margin-top: 50%;
      margin-bottom: 20px;
      position: relative;
      transition: all 0.3s linear 0s;
      grid-auto-flow: column;
      grid-auto-columns: 100in;
      scroll-snap-stop: always;
      scroll-snap-type: x mandatory;
      &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.136);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #11171a86;
        border-radius: 10px;
      }
      li {
        width: 100vw;
        height: 100vw;
        a {
          .image {
            height: 100vw;
          }
        }
        .overlay {
          width: 100vw;
          height: 100vw;
        }
      }
    }
  }
}
</style>