<template>
  <footer class="grid">
    <div class="footer row">
      <div class="col">
        <div class="footer-container">
          <h5>Super English Force</h5>
          <h5>Saiprise Infortainment</h5>
          <div>
            <ul>
              <li class="d-flex align-items-center">
                <i class="fas fa-mobile-alt"></i>
                <a href="tel:+84327864586"> <p>(+84) 327 864 586</p></a>
              </li>
              <li class="d-flex align-items-center">
                <i class="far fa-envelope"></i>                
                <a href="mailto:mindspace319319@gmail.com"><p>mindspace319319@gmail.com</p> </a>
              </li>
              <li class="socialmedia">
                <a href="https://www.facebook.com/Mind-Space-113327754191997" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UC6Q4UCG-V3FUym_XfQPU-UA"
                  target="_blank"
                >
                  <i class="fab fa-youtube"></i>
                </a>
                <a href="https://www.instagram.com/saiprise/ " target="_blank">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="footer-container contact">
          <h5 @click="$router.push('/privacy-policy')" style="">
            Privacy Policy
          </h5>
          <h5 @click="handleClick()" style="">Careers</h5>
          <div>
            <ul>
              <li class="socialmedia">
                <a href="#" target="_blank">
                  <img
                    :src="
                      require(`../../../../public/img/logo1.png`)
                    "
                  />
                </a>
                <a
                  href="#"
                  target="_blank"
                >
                  <img
                    :src="
                      require(`../../../../public/img/logo2.png`)
                    "
                  />
                </a>
                <a href="#" target="_blank">
                  <img
                    :src="
                      require(`../../../../public/img/logo3.png`)
                    "
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="copyright"><p>&copy; Copyright 2022 Saiprise</p></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    handleClick() {
      this.$router.push('/contact');
    },
  },
};
</script>

<style scoped lang="scss">
.grid {
  background-color: #000000;
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer {
  width: 1000px;
  display: flex;
  margin-top: 38px;
  border-top: 1px solid #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #000000;
}
.footer-container {
  color: #fff;
}

h1 {
  font-size: 24px;
}
p {
  font-size: 13px;
}
ul {
  padding-left: 0;
}
ul li {
  list-style: none;
  font-size: 13px;
  align-items: center;
}
ul li i {
  line-height: 13px;
  font-size: 13px;
  margin: 0 8px 0 0;
  justify-content: center;
  align-items: center;
}
ul li p {
  color: #fff;
  margin: 8px 8px 8px 0;
  line-height: 20px;
}
.copyright {
  text-align: center;
}
.copyright p {
  font-size: 13px;
  line-height: 20px;
  color: rgb(255, 255, 255);
}
.contact:hover {
  cursor: pointer;
}
ul {
  li {
    &.socialmedia {
      display: flex;
      flex-direction: row;
      margin-top: 1.5rem;
      a {
        align-items: flex-end;
        i {
          font-size: 32px;
        }
        &:nth-child(1) {
          i {
            color: #2fa4ff;
          }
          &:hover {
            i {
              color: rgba(42, 78, 207, 0.667);
            }
          }
        }
        &:nth-child(2) {
          i {
            color: #f90716;
          }
          &:hover {
            i {
              color: rgba(241, 63, 63, 0.578);
            }
          }
        }
        &:nth-child(3) {
          i {
            color: #b21f66;
          }
          &:hover {
            i {
              color: rgba(207, 136, 42, 0.537);
            }
          }
        }
        img {
          width: 70px;
        }
      }
      a + a {
        margin-left: 1.5rem;
      }
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      i {
        font-size: 20px;
      }
    }
  }
}
@media only screen and (max-width: 844px) and (min-width: 320px) {
  .footer {
    padding: 1rem;
  }
  .footer .link {
    display: none;
  }
  .fanpage {
    display: none;
  }
}
</style>
