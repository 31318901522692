<template>
  <div class="header">
    <header :class="{ none: phone }">
      <div class="left">
        <div class="logo">
          <router-link to="/">
            <img
              class="header__logo--img"
              src="../../../../public/logo.png"
              alt=""
            />
          </router-link>
        </div>
        <div class="nav">
          <ul>
            <li>
              <div>
                <p>Thông tin</p>
                <i class="fas fa-angle-down"></i>
              </div>
              <ul class="child-link" id="child1">
                <li>
                  <router-link to="/superenglishforce">Studio</router-link>
                </li>
                <li><router-link to="/">Performance</router-link></li>
                <li>
                  <router-link to="/superenglishforce">Academy</router-link>
                </li>
                <li><router-link to="/selfie-coffee">Cafe</router-link></li>
                <li><router-link to="/">Events</router-link></li>
                <li><router-link to="/">upcoming</router-link></li>
              </ul>
            </li>
            <li>
              <div>
                <p>Cộng sự</p>
                <i class="fas fa-angle-down"></i>
              </div>

              <ul class="child-link" id="child2">
                <li><router-link to="#">Master Phương</router-link></li>
                <li><router-link to="#">others</router-link></li>
              </ul>
            </li>
            <li>
              <div>
                <p>Dịch vụ</p>
                <i class="fas fa-angle-down"></i>
              </div>
              <ul class="child-link" id="child3">
                <li><router-link to="s-dollar.html">S-Coins</router-link></li>
                <li><router-link to="#">others</router-link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <div class="social-network">
          <ul class="menu-links parent-links">
            <li class="primary-links links-dropdown dropdown-socials">
              <router-link
                to="https://www.facebook.com/MindSpace41319"
                target="_blank"
                ><img src="img/icon/facebook.png" alt=""
              /></router-link>
            </li>
            <li>
              <router-link
                to="https://www.instagram.com/mindspace0931131914/"
                target="_blank"
                ><img src="img/icon/instagram.png" alt=""
              /></router-link>
            </li>
            <li>
              <router-link to="#"
                ><img src="img/icon/youtube.png" alt=""
              /></router-link>
            </li>
          </ul>
        </div>
        <div class="language">
          <div class="language-choose">
            <img src="img/icon/vietnam.png" alt="" />
          </div>
          <ul>
            <li>
              <img src="img/icon/vietnam.png" alt="" />
            </li>
            <li>
              <img src="img/icon/UK.jpg" alt="" />
            </li>
            <li>
              <img src="img/icon/korea.png" alt="" />
            </li>
            <li>
              <img src="img/icon/china.png" alt="" />
            </li>
          </ul>
        </div>
        <div class="contact">
          <router-link class="primary-links--n-bg" to="/contact"
            >liên hệ</router-link
          >
        </div>
        <div class="user-register" style="border: none">
          <div v-if="!currentUser" class="signin">
            <router-link class="primary-links--n-bg" to="/signin">
              <i class="fas fa-user"></i>
            </router-link>
          </div>
          <div v-else class="info-login">
            <span>Wellcome! {{ user.fist_name }}</span>
            <div class="log-out-info">
              <router-link class="primary-links--n-bg" to="/profile">
                Account</router-link
              >
              <button @click.prevent="handleSignout" style="color: black">Đăng Xuất</button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="mobile" :class="{ none: !phone }">
      <div class="left">
        <div class="logo">
          <router-link to="/">
            <img
              class="header__logo--img"
              src="../../../../public/logo.png"
              alt=""
            />
          </router-link>
        </div>
        <div class="nav">
          <div class="content_nav">
            <ul :class="{ show: !thisClose }">
              <li>
                <div>
                  <p>Thông tin</p>
                </div>
                <ul class="child-link" id="child1">
                  <li>
                    <router-link to="/superenglishforce">Studio</router-link>
                  </li>
                  <li><router-link to="/">Performance</router-link></li>
                  <li>
                    <router-link to="/superenglishforce">Academy</router-link>
                  </li>
                  <li><router-link to="/selfie-coffee">Cafe</router-link></li>
                  <li><router-link to="/">Events</router-link></li>
                  <li><router-link to="/">upcoming</router-link></li>
                </ul>
              </li>
              <li>
                <div>
                  <p>Cộng sự</p>
                </div>

                <ul class="child-link" id="child2">
                  <li><router-link to="#">Master Phương</router-link></li>
                  <li><router-link to="#">others</router-link></li>
                </ul>
              </li>
              <li>
                <div>
                  <p>Dịch vụ</p>
                </div>
                <ul class="child-link" id="child3">
                  <li><router-link to="s-dollar.html">S-Coins</router-link></li>
                  <li><router-link to="#">others</router-link></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <div class="social-network">
          <i
            class="fa-solid fa-xmark"
            v-if="!thisClose"
            v-on:click="changeClose()"
          ></i>
          <i
            class="fa-solid fa-grip-lines"
            v-if="thisClose"
            v-on:click="changeClose()"
          ></i>
        </div> -->
        <div class="language">
          <div class="language-choose">
            <img src="img/icon/vietnam.png" alt="" />
          </div>
          <ul>
            <li>
              <img src="img/icon/vietnam.png" alt="" />
            </li>
            <li>
              <img src="img/icon/UK.jpg" alt="" />
            </li>
            <li>
              <img src="img/icon/korea.png" alt="" />
            </li>
            <li>
              <img src="img/icon/china.png" alt="" />
            </li>
          </ul>
        </div>
        <div class="contact">
          <router-link class="primary-links--n-bg" to="/contact"
            >liên hệ</router-link
          >
        </div>
        <div class="user-register" style="border: none">
          <div v-if="!currentUser" class="signin">
            <router-link class="primary-links--n-bg" to="/signin">
              <i class="fas fa-user"></i>
            </router-link>
          </div>
          <div v-else class="info-login">
            <span>Wellcome! {{ user.fist_name }}</span>
            <div class="log-out-info">
              <router-link class="primary-links--n-bg" to="/profile">
                Account</router-link
              >
              <button @click.prevent="handleSignout">Đăng Xuất</button>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
export default {
  name: 'HeaderMS',
  data() {
    return {
      close: true,
      phone: false,
    };
  },
  computed: {
    ...mapGetters(['user']),
    currentUser() {
      return this.$store.state.auth.token ? true : false;
    },
    thisClose() {
      if (this.close === true) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations(['setRemoveUser']),
    ...mapActions(['signout', 'getUser']),
    changeClose() {
      if (this.close == true) {
        this.close = false;
      } else {
        this.close = true;
      }
    },
    handleSignout() {
      this.setRemoveUser();
      this.signout();
      this.$router.push('/');
    },
    created() {
      this.getUser();
    },
  },
  created() {
    if (window.innerWidth < 865) {
      this.phone = true;
    }
    if (window.innerWidth > 865) {
      this.phone = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
header {
  position: relative;
  z-index: 9999;
  max-width: 1600px;
  &.none {
    display: none;
  }
  .bg-image {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
    img {
      transform: translate(0px, 0px);
      width: 100%;
      height: 100%;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  .left {
    display: flex;
    .logo {
      img {
        height: 100px;
      }
    }
    .nav {
      ul {
        display: flex;
        margin: 0;
        justify-content: center;
        align-items: center;
        li {
          position: relative;
          width: 150px;
          &:hover ul {
            display: block;
            animation: moveTodown 0.2s ease-in-out;
            animation-delay: 250ms;
            transform: translateY(0px);
          }
          .router-link-exact-active {
            text-decoration: none;
          }
          div {
            display: flex;
            justify-content: center;
            height: 50px;
            align-items: center;
            p {
              color: rgb(255, 255, 255);
              display: flex;
              margin: 0;
            }
            i {
              margin-left: 0.5rem;
              color: white;
            }
          }
          @keyframes moveTodown {
            0% {
              transform: translateY(120px);
              opacity: 0;
            }
            100% {
              transform: translateX(0px);
              opacity: 1;
            }
          }
          ul {
            display: flex;
            flex-direction: column;
            position: absolute;
            align-items: flex-start;
            background-color: white;
            padding: 1rem;
            border-radius: 3px;
            display: none;
            transform: translateY(120px);

            li {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 50px;
              width: 150px;
              &:hover {
                background: black;
                border: 2px solid #d5d5d5;
                a {
                  color: white;
                }
              }
              a {
                color: black;
                text-decoration: none;
                padding: 1rem 0;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    .social-network {
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        display: flex;
        margin: 0;
        li {
          margin: 0 0.2rem;
          background-color: black;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: rgba(255, 255, 0, 0.63);
          }
          a {
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    .language {
      position: relative;
      margin: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;
      &:hover {
        ul {
          display: block;
        }
      }
      .language-choose {
        border-right: 2px solid #d5d5d5;
        padding-right: 0.4rem;
        img {
          width: 30px;
          height: 30px;
        }
      }
      ul {
        position: absolute;
        top: 30px;
        left: -32px;
        display: flex;
        flex-direction: column;
        display: none;
        li {
          margin-top: 0.3rem;
          &:hover {
            border-radius: 50%;
            border: 2px solid #d5d5d5;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .contact {
      background-color: black;
      border-radius: 3px;
      border: 2px solid #d5d5d5;
      height: 50px;
      width: 100px;
      a {
        color: white;
        text-decoration: none;
        line-height: 50px;
      }
    }
    .user-register {
      margin-left: 0.5rem;
      .info-login {
        position: relative;
        &:hover {
          .log-out-info {
            visibility: inherit;
          }
        }
        span {
          color: white;
        }
        .log-out-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: white;
          color: rgb(62, 62, 62);
          padding: 0.5rem;
          position: absolute;
          left: 0;
          border-radius: 5px;
          top: 30px;
          visibility: hidden;
          &::before {
            content: '';
            border: 20px solid;
            border-color: transparent transparent rgb(255, 255, 255) transparent;
            position: absolute;
            right: 0;
            top: -30px;
          }
          a {
            color: black;
            text-decoration: none;
            height: 50px;
            line-height: 50px;
          }
          button {
            border: none;
            background-color: white;
            padding-bottom: 0.5rem;
          }
          a,
          button {
            &:hover {
              color: rgb(99, 99, 99);
            }
          }
        }
      }
      .signin {
        margin-left: 1rem;
        a {
          i {
            color: white;
          }
        }
      }
    }
  }
}

header.mobile {
  width: 100%;
  position: relative;
  z-index: 9999;
  max-width: 1600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  &.none {
    display: none;
  }
  .left {
    display: flex;
    flex-direction: space-between;
    flex-grow: 1;
    .logo {
      img {
        height: 100px;
      }
    }
    .nav {
      color: rgb(0, 0, 0);
      flex-grow: 1;
display: none;
      i {
        font-size: 1.5em;
      }
      .content_nav {
        position: absolute;
        right: 0;
        top: 100px;
        ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: white;
          color: black;
          padding: 1rem;
          border-radius: 10px;
          padding: 0.5rem;
          min-width: 200px;
          transform: translateX(1600px);
          width: 100vw;
          &.show {
            transform: translateX(0);
          }
          li {
            margin: 0.5rem 0;
            text-transform: uppercase;
            color: black;
            &:hover ul {
              display: block;
            }
            .router-link-exact-active {
            }
            div {
              p {
                margin: 0;
              }
              i {
              }
            }
            @keyframes moveTodown {
              0% {
                transform: translateY(120px);
                opacity: 0;
              }
              100% {
                transform: translateX(0px);
                opacity: 1;
              }
            }
            ul {
              display: none;
              transform: translateX(0);
              li {
                text-transform: capitalize;
                margin: 0.5rem 0;
                &:hover {
                  background-color: black;
                  border-radius: 10px;
                  border: 2px solid rgb(89, 89, 89);
                  a {
                    color: white;
                  }
                }
                a {
                  text-decoration: none;
                  color: rgb(61, 61, 61);
                  padding: 0.3rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    i {
      font-size: 1.5rem;
    }
    .social-network {
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        display: flex;
        margin: 0;
        li {
          margin: 0 0.2rem;
          background-color: black;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: rgba(255, 255, 0, 0.63);
          }
          a {
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    .language {
      position: relative;
      margin: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;
      &:hover {
        ul {
          display: block;
        }
      }
      .language-choose {
        border-right: 2px solid #d5d5d5;
        padding-right: 0.4rem;
        img {
          width: 30px;
          height: 30px;
        }
      }
      ul {
        position: absolute;
        top: 30px;
        left: -32px;
        display: flex;
        flex-direction: column;
        display: none;
        li {
          margin-top: 0.3rem;
          &:hover {
            border-radius: 50%;
            border: 2px solid #d5d5d5;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .contact {
      background-color: black;
      border-radius: 3px;
      border: 2px solid #d5d5d5;
      height: 30px;
      width: 70px;
      a {
        color: white;
        text-decoration: none;
        line-height: 30px;
        font-size: 8px;
      }
    }
    .user-register {
      margin-left: 0.5rem;
      .info-login {
        position: relative;
        &:hover {
          .log-out-info {
            visibility: inherit;
          }
        }
        span {
          color: white;
        }
        .log-out-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: white;
          padding: 0.5rem;
          position: absolute;
          left: 0;
          border-radius: 5px;
          top: 30px;
          visibility: hidden;
          &::before {
            content: '';
            border: 20px solid;
            border-color: transparent transparent rgb(255, 255, 255) transparent;
            position: absolute;
            right: 0;
            top: -30px;
          }
          a {
            color: black;
            text-decoration: none;
            height: 50px;
            line-height: 50px;
          }
          button {
            border: none;
            background-color: white;
            padding-bottom: 0.5rem;
          }
          a,
          button {
            &:hover {
              color: rgb(99, 99, 99);
            }
          }
        }
      }
      .signin {
        margin-left: 1rem;
        a {
          i {
            color: white;
          }
        }
      }
    }
  }
}
</style>
