<template>
  <div>
    <div v-for="(course, index) in course" :key="index">
      <section class="banner">
        <div class="contain">
          <h1>{{ course.name }}</h1>
          <Carousel
            :itemsToShow="1"
            :wrapAround="true"
            :autoplay="3000"
            :transition="3000"
          >
            <Slide v-for="(img, index) in course.image" :key="index">
              <div class="img">
                <img
                  :src="
                    require(`../../../../public/img/academy/sef/teacher/${img}`)
                  "
                />
              </div>
            </Slide>
            <template #addons>
              <pagination />
            </template>
          </Carousel>
        </div>
      </section>
      <Workshops :workshops="course.workshops" />
      <section class="exam">
        <h2>{{ course.description.name }}</h2>
        <Carousel
          :itemsToShow="1"
          :wrapAround="true"
          :autoplay="3000"
          :transition="3000"
        >
          <Slide v-for="(img, index) in course.description.image" :key="index">
            <div class="contain">
              <div class="img">
                <img
                  :src="
                    require(`../../../../public/img/academy/sef/teacher/${img}`)
                  "
                />
              </div>
            </div>
          </Slide>
          <template #addons>
            <pagination />
          </template>
        </Carousel>
      </section>
    </div>
  </div>
</template>

<script>
import Workshops from '../Workshops/workshops.vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
export default {
  name: 'CourseDetails',
  components: {
    Carousel,
    Slide,
    Pagination,
    Workshops,
  },
  data() {
    return {
      course: [],
      courses: [
        {
          id: 1,
          name: 'ielts guarantee workshops',
          image: [
            'Promo 2022.02-04.png',
            'Promo 2022.02-06.png',
            'Promo 2022.02-08.png',
            'Promo 2022.02-10.png',
            'Promo 2022.02-14.png',
            'Promo 2022.02-16.png',
          ],
          workshops: [
            {
              id: 1,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 2,
              name: '24 workshops covering all topics of IELTS exams',
              path: 'workshop2.png',
            },
            {
              id: 3,
              name: 'Mock Exam practice by former IELTS examiners',
              path: 'workshop3.png',
            },
          ],
          description: {
            name: 'Exam Takers Achivement/ Class / Benefits',
            image: [
              'Promo 2022.02-04.png',
              'Promo 2022.02-06.png',
              'Promo 2022.02-08.png',
              'Promo 2022.02-10.png',
              'Promo 2022.02-14.png',
              'Promo 2022.02-16.png',
            ],
          },
        },
        {
          id: 2,
          name: 'super english club',
          image: [
            'Promo 2022.02-04.png',
            'Promo 2022.02-06.png',
            'Promo 2022.02-08.png',
            'Promo 2022.02-10.png',
            'Promo 2022.02-14.png',
            'Promo 2022.02-16.png',
          ],
          workshops: [
            {
              id: 2,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 1,
              name: 'Real Life Conversations',
              path: 'workshop4.png',
            },
          ],
          description: {
            name: 'Club student testmonial / Class / Benefits / Why choose us',
            image: [
              'Promo 2022.02-04.png',
              'Promo 2022.02-06.png',
              'Promo 2022.02-08.png',
              'Promo 2022.02-10.png',
              'Promo 2022.02-14.png',
              'Promo 2022.02-16.png',
            ],
          },
        },
        {
          id: 3,
          name: 'english improvement workshops',
          image: [
            'Promo 2022.02-04.png',
            'Promo 2022.02-06.png',
            'Promo 2022.02-08.png',
            'Promo 2022.02-10.png',
            'Promo 2022.02-14.png',
            'Promo 2022.02-16.png',
          ],
          workshops: [
            {
              id: 1,
              name: 'Prounciation correction',
              path: 'workshop1.png',
            },
            {
              id: 2,
              name: 'Real Life Conversations',
              path: 'workshop2.png',
            },
          ],
          description: {
            name: 'Exam Takers Achivement/ Class / Benefits',
            image: [
              'Promo 2022.02-04.png',
              'Promo 2022.02-06.png',
              'Promo 2022.02-08.png',
              'Promo 2022.02-10.png',
              'Promo 2022.02-14.png',
              'Promo 2022.02-16.png',
            ],
          },
        },
      ],
    };
  },
  methods: {
    getCourses() {
      var search = this.$route.params.id.replace(/-/g, ' ');
      let obj = this.courses.find((data) => data.name.toLowerCase() == search);
      this.course[0] = obj;
    },
  },
  async created() {
    await this.getCourses();
  },
};
</script>

<style lang="scss" scoped>
h1,
h2 {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 1rem;
}
.banner {
  .contain {
    .img {
      width: 100%;
      height: calc(100vw * 0.341);
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
.exam {
  .contain {
    .img {
      width: 100%;
            height: calc(100vw * 0.341);
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
