<template>
  <div class="form-login">
    <form @submit.prevent="signUpButtonPressed" v-if="!loading">
      <span> Member Signup </span>
      <div class="profile">
        <div class="first">
          <!-- <div class="avartar">
            <img class="header__logo--img" :src="previewImage" alt="" />
            <input
              type="file"
              id="avatar"
              name="avatar"
              accept="image/png, image/jpeg"
              @change="uploadImage"
            />
          </div> -->
          <!-- <div>
            <label>Birth Day</label>
            <input type="date" v-model="user.Birthday" />
          </div> -->
        </div>
        <div class="second">
          <div>
            <label>First Name</label>
            <input
              type="text"
              v-model="user.FistName"
              name="fist-name"
              placeholder="Peter"
              required
            />
          </div>
          <div>
            <label>Last Name</label>
            <input
              type="text"
              v-model="user.LastName"
              name="last-name"
              placeholder="Nguyen"
              required
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="text"
              v-model="user.Email"
              required
              name="email"
              placeholder="example@gmail.com"
            />
          </div>
        </div>
        <div class="thirst">
          <div>
            <label for=""> Phone</label>
            <input
              type="text"
              v-model="user.PhoneNumber"
              name="phone"
              placeholder="0123456789"
              required
            />
          </div>
          <div>
            <label for=""> Password</label>
            <input
              type="password"
              v-model="user.Password"
              required
              name="pass"
              placeholder="********"
            />
          </div>
          <div>
            <label for="">Retype Password</label>
            <input
              type="password"
              v-model="user.RePassword"
              name="re-pass"
              required
              placeholder="*******"
            />
          </div>
        </div>
        <div class="fourth">
          <div>
            <label for=""> Address</label>
            <input
              type="text"
              name="text"
              v-model="user.Address"
              placeholder="100 Trần Hưng Đạo, Phạm Ngũ Lão, Quận 1"
            />
          </div>
          <div class="checkbox" style="display: flex; flex-direction: column; align-items: center;">
            <label for="">How did you hear about us?</label>
            <div class="checkbox2">
              <ul>
                <li v-for="(howyouknow, index) in howyouknows" :key="index">
                <span>
                    {{ howyouknow.name }}
                </span>
                  <input
                    v-model="howyouknow.checked"
                    type="checkbox"
                    name="check"
                  />
                </li>
              </ul>
              <textarea
                name="reson"
                v-model="other"
                rows="4"
                cols="50" v-if="howyouknows[howyouknows.length - 1].checked || howyouknows[howyouknows.length - 2].checked || howyouknows[howyouknows.length - 3].checked"
                placeholder="Please specify..... "
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button :disabled="isDisabled">Sign up</button>
      </div>
      <div>
        <router-link to="/signin">
          You have acount, signin?
          <i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
        </router-link>
      </div>
    </form>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import axios from '../../stores/api';
import { mapMutations, mapActions } from 'vuex';
import Loading from '../loading.vue';
export default {
  name: 'SignIn',
  components: {
    Loading,
  },
  data() {
    return {
      isDisabled: false,
      loading: true,
      previewImage: '',
      other:'',
      user: {
        FistName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Password: '',
        RePassword: '',
        Address: '',
        Message: '',
      },
      howyouknows: [
        {
          name: 'Throught a friend (please specify...)',
          checked: false,
        },
        {
          name: 'Social media post(Facebook, IG, Zalo,etc.)',
          checked: false,
        },
        {
          name: 'Other (please specify...............):',
          checked: false,
        },
      ],
    };
  },
  computed: {},
  methods: {
    ...mapMutations(['setUser', 'setToken']),
    ...mapActions(['getUser']),
    // uploadImage(e) {
    //   const file = e.target.files[0];
    //   this.user.Image = file;
    //   this.previewImage = URL.createObjectURL(file);
    // },
    signUpButtonPressed() {
      let userchoose =  [];
      this.howyouknows.map(item =>{
        if(item.checked === true){
          userchoose.push(item.name)
        }

      })
      this.user.Message = userchoose.toString() + ' '+this.other;
      if (
        this.user.FistName == '' ||
        this.user.FistName == null ||
        this.user.FistName == undefined
      ) {
        this.$toast.error(`Fist Name is empty.`);
        return;
      }
      if (
        this.user.LastName == '' ||
        this.user.LastName == null ||
        this.user.LastName == undefined
      ) {
        this.$toast.error(`Last Name is empty.`);
        return;
      }
      if (
        this.user.Email == '' ||
        this.user.Email == null ||
        this.user.Email == undefined
      ) {
        this.$toast.error(`Email is empty.`);
        return;
      }
      if (
        this.user.PhoneNumber == '' ||
        this.user.PhoneNumber == null ||
        this.user.PhoneNumber == undefined
      ) {
        this.$toast.error(`Phone Number is empty.`);
        return;
      }
      if (this.user.PhoneNumber.length !== 10) {
        this.$toast.error(` Phone 10 mumber`);
        return;
      }
      if (
        this.user.Password == '' ||
        this.user.Password == null ||
        this.user.Password == undefined
      ) {
        this.$toast.error(`Password is empty.`);
        return;
      }
      if (this.user.Password.length < 6) {
        this.$toast.error(`Password At least 6 characters.`);
        return;
      }
      if (!(this.user.Password === this.user.RePassword)) {
        this.$toast.error(`RePassword incorrect.`);
        return;
      }
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 3000);
      const formData = new FormData();
      formData.append('FistName', this.user.FistName);
      formData.append('LastName', this.user.LastName);
      formData.append('PhoneNumber', this.user.PhoneNumber);
      // formData.append('Image', this.user.Image);
      formData.append('Email', this.user.Email);
      formData.append('Password', this.user.Password);
      formData.append('Address', this.user.Address);
      formData.append('Message', this.user.Message);
      axios
        .post(`/v1/api/auth/signup`, this.user)
        .then((res) => res.data)
        .then((result) => {
          this.setUser(result.data.user);
          this.setToken(result.data.accessToken);
          this.getUser();
          this.$router.push('/');
          this.$toast.success(`Signup Success`);
          if (result.data.user.role === 'admin') {
            this.$router.push('/dashboard');
          } else {
            this.$router.push('/');
          }
        })
        .catch((err) => {
          this.$toast.error(`Signup ${err.response.data.message}`);
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 600);
  },
};
</script>

<style lang ="scss" scoped>
.form-login {
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    background-color: #ff46038c;
    border-radius: 10px;
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem;
    padding: 1rem;
    span {
      font-size: 2.5em;
      font-family: 'Great Vibes', cursive;
      color: rgb(0, 0, 0);
    }
    .profile {
      .first,
      .second,
      .thirst,
      .fourth {
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          margin: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          input {
            padding: 0.5rem;
          }
          input[type='text'] {
            border: 3px solid rgb(235, 103, 2);
            border-radius: 7px;
          }
          input[type='password'] {
            border: 3px solid rgb(235, 103, 2);
            border-radius: 7px;
          }
          input[type='text']:focus {
            border: 3px solid rgb(85, 85, 84);
          }
          input[type='password']:focus {
            border: 3px solid rgb(82, 82, 82);
          }
        }
      }
      .first,
      .fourth {
        justify-content: space-between;
        flex-direction: column;
        align-items:flex-start;
        .avartar {
          height: 200px;
          width: 200px;
          background-color: #d5d5d5;
          border: 1px solid #d5d5d5;
          border-radius: 5px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          flex-direction: column;
          input {
            color: #d5d5d500;
            background-color: #fa7304;
          }
          img {
            width: 100%;
            height: 80%;
            object-fit: cover;
          }
          ::-webkit-file-upload-button {
            background: rgba(223, 84, 15, 0);
            color: rgba(156, 130, 130, 0);
            width: 0px;
            height: 0px;
            font-size: 16px;
            border: none;
            padding: 1em;
          }
        }
        .checkbox {
          width: 100%;
          .checkbox2 {
            width: 100%;
            ul {
              width: 100%;
              background-color: white;
              padding: 1rem;
              border-radius:0.4rem;
              li {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              }
            }

        textarea{
          width: 100%;
          padding: 0.5rem;
        }
          }
        }
      }
    }
    div {
      button {
        height: 50px;
        width: 200px;
        border: 4px solid #d5d5d5;
        background-color: #000000;
        color: white;
        border-radius: 10px;
        margin: 2rem 0;
        &:hover {
          background-color: #f4540f;
          color: black;
        }
      }
      span {
        font-size: 16px;
        font-family: 'Josefin Sans', sans-serif;
      }
      a {
        text-decoration: none;
        color: rgb(44, 44, 44);
        margin: 2rem 0;
      }
    }
  }
  .checkbox {
    flex-direction: column;
    display: flex;
  }
}
@media screen and (max-width: 816px) {
  .form-login {
    display: flex;
    flex-direction: column;
    form {
      width: 100vw;
      overflow: hidden;
      span {
        font-size: 1.5em;
      }
      .profile {
        .first,
        .second,
        .thirst,
        .fourth {
          flex-direction: column;
          div {
            margin: 1rem;
            flex-direction: row;
            justify-content: space-between;
            width: 50%;
            input {
              padding: 0.5rem;
              width: 70%;
            }
            input[type='text'] {
              border: 3px solid rgb(235, 103, 2);
              border-radius: 7px;
            }
            input[type='password'] {
              border: 3px solid rgb(235, 103, 2);
              border-radius: 7px;
            }
            input[type='text']:focus {
              border: 3px solid rgb(85, 85, 84);
            }
            input[type='password']:focus {
              border: 3px solid rgb(82, 82, 82);
            }
          }
        }
        .first,
        .fourth {
          align-items: center;
                  .checkbox {
          width: 100%;
          .checkbox2 {
            width: 100%;
            flex-direction: column;
            ul {
              width: 100%;
              background-color: white;
              padding: 1rem;
              border-radius:0.4rem;
              li {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                span{
text-align: left;
                }
                input{
                  width: 20px;
                }
              }
            }

        textarea{
          width: 100%;
          padding: 0.5rem;
        }
          }
        }
        }
      }
      div {
        button {
          height: 50px;
          width: 200px;
          border: 4px solid #d5d5d5;
          background-color: #000000;
          color: white;
          border-radius: 10px;
          margin: 2rem 0;
          &:hover {
            background-color: #f4540f;
            color: black;
          }
        }
        span {
          font-size: 16px;
          font-family: 'Josefin Sans', sans-serif;
        }
        a {
          text-decoration: none;
          color: rgb(44, 44, 44);
          margin: 2rem 0;
        }
      }
    }
  }
}
</style>