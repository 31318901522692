<template>
  <div class="form-login">
    <form @submit.prevent="signInButtonPressed">
      <span> Member Signin</span>
      <div class="profile">
        <div class="first">
          <label>Email:</label>
          <input
            type="text"
            name="email"
            v-model="Email"
            required
            placeholder="example@gmail.com"
          />
        </div>
        <div class="second">
          <label for=""> Password:</label>
          <input
            type="password"
            name="pass"
            v-model="Password"
            required
            placeholder="123456"
          />
        </div>
      </div>
      <div>
        <button :disabled ="isDisabled">Sign In</button>
      </div>
      <div>
        <span> Forgot </span>
        <a href="#"> Username / Password? </a>
      </div>
      <div>
        <router-link to="/signup">
          Create your Account
          <i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import axios from '../../stores/api';
import { mapMutations, mapActions } from 'vuex';
export default {
  name: 'Signin',
  data() {
    return {
      isDisabled: false,
      Email: '',
      Password: '',
    };
  },
  methods: {
    ...mapMutations(['setUser', 'setToken']),
    ...mapActions(['getUser']),
    signInButtonPressed() {
      if (this.Email == '' || this.Email == null || this.Email == undefined) {
        this.$toast.error(`Email is empty.`);
        return;
      }
      if (
        this.Password == '' ||
        this.Password == null ||
        this.Password == undefined
      ) {
        this.$toast.error(`Password is empty.`);
        return;
      }
      if (this.Password.length < 6) {
        this.$toast.error(`Password At least 6 characters.`);
        return;
      }
      this.isDisabled=true;
      setTimeout(() =>{
        this.isDisabled = false;
      }, 3000)
      axios
        .post(`/v1/api/auth/signin`, {
          Email: this.Email,
          Password: this.Password,
        })
        .then((res) => res.data)
        .then((result) => {
          this.$toast.success(`Signin Success`);
          this.setUser(result.data.user);
          this.setToken(result.data.accessToken);
          this.$router.push('/');
          this.getUser();
          // if (result.data.user.role === 'admin') {
          //   this.$router.push('/dashboard');
          // } else {
          // if (window.history.length > 2) {
          //   this.$router.go(-2);
          // } else {
          //   this.$router.push('/');
          // }
          // }
        })
        .catch((err) => {
          this.$toast.error(`Signin ${err.response.data.message}`);
        });
    },
  },
};
</script>

<style lang ="scss" scoped>
.form-login {
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    background-color: #ff460357;
    border-radius: 10px;
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem;
    padding: 1rem;
    span {
      font-size: 2.5em;
      font-family: 'Great Vibes', cursive;
      color: rgb(0, 0, 0);
    }
    .profile {
      .first,
      .second {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 2rem 0;
        input[type='text'] {
          border: 3px solid rgb(235, 103, 2);
          border-radius: 7px;
        }
        input[type='password'] {
          border: 3px solid rgb(235, 103, 2);
          border-radius: 7px;
        }
        input[type='text']:focus {
          border: 3px solid rgb(85, 85, 84);
        }
        input[type='password']:focus {
          border: 3px solid rgb(82, 82, 82);
        }
        label {
          margin: 1rem;
        }
        input {
          padding: 0.5rem;
        }
      }
    }
    div {
      button {
        height: 50px;
        width: 200px;
        border: 4px solid #d5d5d5;
        background-color: #000000;
        color: white;
        border-radius: 10px;
        margin: 2rem 0;
        &:hover {
          background-color: #f4540f;
          color: black;
        }
      }
      span {
        font-size: 16px;
        font-family: 'Josefin Sans', sans-serif;
      }
      a {
        text-decoration: none;
        color: rgb(44, 44, 44);
        margin: 2rem 0;
      }
    }
  }
}
@media screen and (max-width: 846px) {
  .form-login {
    form {
      width: 100vw;
      margin: 0.5rem;
      padding: 0.5rem;
      span {
        font-size: 1.4em;
      }
      .profile {
        .first,
        .second {
          margin: 1rem 0;
        }
      }
      div {
        button {
          margin: 1rem 0;
        }
        span {
          font-size: 16px;
        }
      }
    }
  }
}
</style>