// import axios from '../api';

const state = {
  status: '',
  token: localStorage.getItem('token') || null,
  user: {},
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
};

const mutations = {
  setUser: (state, user) => {
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },
  setToken: (state, token) => {
    state.token = token;
    localStorage.setItem('token', token);
  },
  setRemoveUser: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  },
  signout: (state) => (state.token = null),
};

const actions = {
  signout({ commit }) {
    commit('signout');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
