<template>
  <div>
    <section v-if="!loading">
      <carousel
        :itemsToShow="1"
        :wrapAround="true"
        :autoplay="3000"
        :transition="3000"
      >
        <slide v-for="rorateBanner in rorateBanners" :key="rorateBanner">
          <div class="content-banner">
            <div class="content-banner-item">
              <div class="img">
                <img
                  :src="
                    require(`../../../public/img/counpons/${rorateBanner.path}`)
                  "
                />
              </div>
              <div class="description">
                <p><span v-html="rorateBanner.description"></span></p>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </section>
    <section v-if="!loading">
      <h1
        class="main-title"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1500"
        data-aos-offset="0"
        data-aos-delay="100"
      >
        Interactive Fun-Filled Café
      </h1>
      <div class="container-inforcoffee">
        <div class="content">
          <div class="carousel-1">
            <carousel
              :itemsToShow="1"
              :wrapAround="true"
              :autoplay="3000"
              :transition="3000"
            >
              <slide v-for="rorateCF in rorateCF" :key="rorateCF">
                <div class="content-inforcoffee">
                  <div class="img">
                    <img
                      :src="
                        require(`../../../public/img/selfiecoffee/${rorateCF.path}`)
                      "
                    />
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
          <p>
            Minh city, blend oftop or the outdoor area of the building, enjoy on
            their own without disturbances. There will be different themes
            providing chilling options for different members such as music/movie
            lovers. Serving simplistic and special F&B powered by
          </p>
        </div>
      </div>
    </section>
    <section style="position: relative" v-if="!loading">
      <div class="image" style="background-image: url('img/space-bg1.jpg')">
        <div class="content-text">
          <p>
            lorem ipsum dolor sit amet, consect lorem lorem, sed diam nonum
            lorem ipsum dolor sit amet, consect lorem lorem, sed diam nonum
            lorem ipsum dolor sit amet, consect lorem lorem, sed diam nonum
            lorem ipsum dolor sit amet, consect lorem lorem, sed diam nonum
            lorem ipsum dolor sit amet, consect lorem lorem, sed diam nonum
            lorem ipsum dolor sit amet, consect lorem lorem, sed diam nonum
            lorem ipsum dolor sit amet, consect lorem lorem, sed diam nonum
            lorem ipsum dolor sit amet, consect lorem lorem, sed diam nonum
            lorem ipsum dolor sit amet, consect lorem lorem, sed diam nonum
          </p>
          <a
            href="our-menu-drinks.html"
            style="
              position: absolute;
              bottom: 1rem;
              left: 50%;
              transform: translateX(-50%);
              text-decoration: underline;
              color: black;
            "
            >Our Menu</a
          >
        </div>
      </div>
    </section>
    <section v-if="!loading">
      <div class="content-swings">
        <div class="image">
          <img src="img/detail/wings6.jpeg" alt="" />
        </div>
        <div class="description">
          <p>
            Selfie Café is an interactive fun-filled cafe. We not only provide
            tasty food & beverage, but fullfill your imagination.
            ................................
          </p>
        </div>
      </div>
    </section>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import Loading from '../loading.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
export default {
  name: 'CFHome',
  components: {
    Carousel,
    Slide,
    Loading,
  },
  data() {
    return {
      loading: true,
      rorateBanners: [
        {
          path: 'voucher.jpg',
          description:
            '<p>Join our<br/> Membership to get 50K voucher on all drinks & food<br/></p>',
        },
        {
          path: 'milktea.jpg',
          description: ' Our Signature drinks',
        },
        {
          path: 'bestsummer.jpg',
          description: 'Your Best Summer Choice',
        },
      ],
      rorateCF: [
        {
          path: '1.jpg',
        },
        {
          path: '2.jpg',
        },
        {
          path: '3.jpg',
        },
        {
          path: '4.jpg',
        },
        {
          path: '5.jpg',
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 600);
  },
};
</script>

<style lang="scss" scoped>
.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content-banner-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: calc(100vw * 0.34);
    overflow: hidden;
    background-color: #000000;

    .img {
      width: 70%;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .description {
      width: 30%;
      color: rgb(0, 0, 0);
      background-color: #d5d5d5;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
      }
    }
  }
}
.main-title {
  font-size: 1rem;
  width: 50%;
  border-radius: 30px;
  height: 76px;
  justify-content: center;
  align-items: center;
  background-color: black;
  border: 4px solid #d5d5d5;
  text-transform: capitalize;
  margin-top: 20px;
  color: white;
  line-height: 76px;
}
.container-inforcoffee {
  width: 100%;
  max-height: calc(100% * 0.34);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 1rem;
  .content {
    max-width: 1600px;
    display: flex;
    justify-content: center;
    align-items: center;
    .carousel-1 {
      width: 50%;
      .content-inforcoffee {
        width: 100%;
        padding: 0.5rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 30px;
          border: 4px solid #d5d5d5;
        }
      }
    }
    p {
      width: 50%;
      color: white;
    }
  }
}
.image {
  background-attachment: fixed;
  width: 68vw;
  height: 100vh;
  max-width: 972.4px;
  background-size: cover;
  .content-text {
    position: absolute;
    top: 0;
    right: 0;
    width: 32vw;
    max-width: 457.6px;
    height: 100%;
    background-color: hsla(0, 0%, 100%, 0.827);
    justify-content: center;
    padding: 1rem;
  }
}
.content-swings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  .image {
    width: 35%;
    overflow: hidden;
    img {
      border: 4px solid #d5d5d5;
      border-radius: 30px;
      width: 100%;
      object-fit: cover;
    }
  }
  .description {
    width: 35%;
    color: white;
  }
}
@media screen and (max-width: 768px) {
  .container-inforcoffee {
    .content {
      flex-direction: column;
      .carousel-1 {
        width: 100%;
        .content-inforcoffee {
          img {
          }
        }
      }
      p {
        width: 100%;
        padding: 0.5rem;
      }
    }
  }
  .image {
    height: 100vw;
    .content-text {
      width: 100vw;
      padding: 0.5rem;
    }
  }
  .content-swings {
    flex-direction: column;
    .image {
      width: 100vw;
      img {
        border-radius: 0px;
      }
    }
    .description {
      width: 100vw;
      padding: 0.5rem;
    }
  }
}
</style>