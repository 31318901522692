<template>
  <div class="error404">
    <div class="errorCode">404</div>
    <div class="errorMessage">Page not found</div>
  </div>
</template>

<script>
export default {
  name: 'Notfound',
};
</script>

<style scope lang="scss">
.error404 {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .errorCode {
    font-size: 145px;
  }
}
</style>